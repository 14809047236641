import React, { useEffect, useState } from 'react';
import SidebarAdmin from '../components/SidebarAdmin';
import TopBar from '../components/TopBar';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import axios from 'axios';

interface Position {
    symbol: string;
    type: string;
    PNL: number;
    [key: string]: any;
}

interface Price {
    symbol: string;
    ask: string;
    bid: string;
}

const OpenPositionsAdmin: React.FC = () => {
    const [positions, setPositions] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState<any>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    useEffect(() => {
        fetchPositions();
        const interval = setInterval(() => {
            fetchPositions();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const fetchPositions = async () => {
        try {
            const response = await axios.get('http://82.115.20.86:5000/api/open-positions-admin');
            const { positions, prices } = response.data;

            const updatedPositions = positions.map((position: Position) => {
                const priceData = prices.find(
                    (p: Price) => p.symbol.trim().toUpperCase() === position.symbol.trim().toUpperCase()
                );

                if (!priceData) {
                    console.warn(`Price data not found for symbol: ${position.symbol}`);
                    return { ...position, currentPrice: 'N/A' };
                }

                const { ask, bid } = priceData;
                const currentPrice =
                    position.type === 'Buy Limit' || position.type === 'Buy Stop'
                        ? parseFloat(ask)
                        : parseFloat(bid);

                return {
                    ...position,
                    currentPrice: currentPrice.toFixed(
                        position.symbol === 'XAGUSD...'
                            ? 4
                            : ['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(position.symbol)
                                ? 2
                                : ['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(position.symbol)
                                    ? 3
                                    : 5
                    ),
                };
            });

            setPositions((prev) => {
                const uniquePositions = updatedPositions.filter(
                    (pos: Position, index: number, self: Position[]) =>
                        index === self.findIndex((p: Position) => p.id === pos.id) // فقط یکبار مقدار `id` نمایش داده شود
                );
                return prev.length === 0 ? uniquePositions : prev.map((prevPos) =>
                    uniquePositions.find((newPos: Position) => newPos.id === prevPos.id) || prevPos
                );
            });
            setLoading(false);
        } catch (error) {
            console.error('Error fetching positions:', error);
            setLoading(false);
        }
    };
    const handleOpenDialog = (details: any) => {
        setSelectedDetails(details);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedDetails(null);
    };
    const [isTopBarVisible, setTopBarVisible] = useState(true);

    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <SidebarAdmin open={sidebarOpen} onClose={toggleSidebar} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar onMenuClick={toggleSidebar} onSearch={() => { }} isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible} />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: { xs: '230px', sm: '64px' },
                        padding: { xs: 2, sm: 3 },
                        overflow: 'auto',
                    }}
                >
                    {loading ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>Loading...</Typography>
                    ) : positions.length === 0 ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>No positions found.</Typography>
                    ) : (
                        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#002B5B' }}>
                                    <TableRow>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Username</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Strategy Id</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Symbol</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Lot</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Type</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Price</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Current Price</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>PNL</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>SL</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>TP</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Order Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {positions.map((position, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{position.username}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                <Button
                                                    onClick={() => handleOpenDialog(position)}
                                                    variant="outlined"
                                                    sx={{
                                                        color: '#fff',
                                                        borderColor: '#1e88e5',
                                                        backgroundColor: '#1e88e5',
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            backgroundColor: '#1565c0',
                                                            borderColor: '#1565c0',
                                                        },
                                                        borderRadius: '8px',
                                                        fontWeight: 'bold',
                                                        padding: '4px 8px',
                                                    }}
                                                >
                                                    {position.strategy_id}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{position.symbol}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(position.lot).toFixed(2)}</TableCell>
                                            <TableCell
                                                sx={{
                                                    color: position.type === '0' ? 'green' : position.type === '1' ? 'red' : 'inherit',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {position.type === '0' ? 'Buy' : position.type === '1' ? 'Sell' : 'Unknown'}
                                            </TableCell>

                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(position.price).toFixed(2)}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{position.currentPrice || '-'}</TableCell>
                                            <TableCell
                                                sx={{
                                                    color: parseFloat(position.PNL) > 0 ? 'green' : 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {parseFloat(position.PNL).toFixed(2)}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(position.sl).toFixed(2)}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(position.tp).toFixed(2)}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{position.ordertime}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Box>

            {/* Dialog for Details */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ fontWeight: 'bold' }}>Position Details</DialogTitle>
                <DialogContent>
                    {selectedDetails && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Username:</strong> {selectedDetails.username}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Strategy Name:</strong> {selectedDetails.strategy_name}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Instrument Symbol:</strong> {selectedDetails.instrument_symbol}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Trade Style:</strong> {selectedDetails.trade_style}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Timeframe Analysis:</strong> {selectedDetails.timeframe_analysis}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Market Conditions:</strong> {selectedDetails.market_conditions}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Risk Reward Ratio:</strong> {selectedDetails.risk_reward_ratio}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Capital Allocated:</strong> {selectedDetails.capital_allocated}</Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default OpenPositionsAdmin;

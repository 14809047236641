import React, { useEffect, useRef, useState } from "react";
import { createChart, IChartApi, ISeriesApi, UTCTimestamp } from "lightweight-charts";
import { useTheme, useMediaQuery, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Checkbox } from "@mui/material";
import axios from "axios";

interface Candle {
  time: string;
  open: number;
  high: number;
  low: number;
  close: number;
}
interface TradingViewWidgetProps {
  onFullscreenChange?: (isFullscreen: boolean) => void;
}

const TradingViewWidget: React.FC<TradingViewWidgetProps> = ({ onFullscreenChange }) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi | null>(null);
  const candleSeriesRef = useRef<ISeriesApi<"Candlestick"> | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [currentSymbol, setCurrentSymbol] = useState<string>("BTCUSD");
  const [timeframe, setTimeframe] = useState<string>("60");
  const [showSL, setShowSL] = useState(false);
  const [showTP, setShowTP] = useState(false);
  const [showOpenPrice, setShowOpenPrice] = useState(false);

  const [tempShowSL, setTempShowSL] = useState(showSL);
  const [tempShowTP, setTempShowTP] = useState(showTP);
  const [tempShowOpenPrice, setTempShowOpenPrice] = useState(showOpenPrice);
  const [openLineMenu, setOpenLineMenu] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // شناسایی نسخه موبایل

  // مقدار قابل تنظیم برای ارتفاع
  const heightOffset = isMobile ? 530 : 0; // مقدار پیش‌فرض برای موبایل

  const determinePrecision = (symbol: string): number => {
    if (["BTCUSD", "ETHUSD", "XAUUSD...", "US30", "UT100", "US500"].includes(symbol)) {
      return 2;
    }
    if (["USDJPY...", "EURJPY...", "GBPJPY..."].includes(symbol)) {
      return 3;
    }
    if (["XAGUSD..."].includes(symbol)) {
      return 4;
    }
    if (["EURUSD...", "GBPUSD...", "USDCHF...", "EURNZD..."].includes(symbol)) {
      return 5;
    }
    return 2;
  };
  const getUserId = () => {
    const token = localStorage.getItem("token");
    if (!token) return null;

    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.id;
    } catch {
      return null;
    }
  };
  const slLineRef = useRef<ISeriesApi<"Line"> | undefined>();
  const tpLineRef = useRef<ISeriesApi<"Line"> | undefined>();
  const openLineRef = useRef<ISeriesApi<"Line"> | undefined>();

  const fetchPositionDetails = async () => {
    try {
      const userId = getUserId();
      if (!userId) {
        console.error("User ID not found. Please login again.");
        return;
      }

      const response = await axios.get("http://82.115.20.86:5000/api/open-positions", {
        params: { userId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const positions = response.data.filter((position: any) => position.symbol === currentSymbol);
      if (slLineRef.current) {
        chartRef.current?.removeSeries(slLineRef.current);
        slLineRef.current = undefined;
      }
      if (tpLineRef.current) {
        chartRef.current?.removeSeries(tpLineRef.current);
        tpLineRef.current = undefined;
      }
      if (openLineRef.current) {
        chartRef.current?.removeSeries(openLineRef.current);
        openLineRef.current = undefined;
      }

      if (positions.length > 0) {
        const { sl, tp, openPrice } = positions[0];

        if (showSL && sl && parseFloat(sl) !== 0) {
          slLineRef.current = chartRef.current?.addLineSeries({ color: "red", lineWidth: 2 });
          slLineRef.current?.setData([{ time: new Date().getTime() / 1000 as UTCTimestamp, value: parseFloat(sl) }]);
        }

        if (showTP && tp && parseFloat(tp) !== 0) {
          tpLineRef.current = chartRef.current?.addLineSeries({ color: "green", lineWidth: 2 });
          tpLineRef.current?.setData([{ time: new Date().getTime() / 1000 as UTCTimestamp, value: parseFloat(tp) }]);
        }

        if (showOpenPrice && openPrice) {
          openLineRef.current = chartRef.current?.addLineSeries({ color: "black", lineWidth: 2 });
          openLineRef.current?.setData([{ time: new Date().getTime() / 1000 as UTCTimestamp, value: parseFloat(openPrice) }]);
        }
      }
    } catch (error) {
      console.error("Error fetching position details:", error);
    }
  };
  const liveCandleTimeRef = useRef<number | null>(null);

  const fetchLiveCandle = async () => {
    const timeframeMapping: { [key: string]: string } = {
      "1": "1m",
      "5": "5m",
      "15": "15m",
      "30": "30m",
      "60": "1h",
      "240": "4h",
      "D": "1d",
      "W": "1w",
      "M": "1M"
    };

    const adjustedTimeframe = timeframeMapping[timeframe] || "1m";  // تبدیل تایم‌فریم به فرمت دیتابیس

    try {
      const response = await axios.get(`http://82.115.20.86:5000/api/live-candle`, {
        params: { symbol: currentSymbol, timeframe: adjustedTimeframe }, // ارسال تایم‌فریم به سرور
      });

      const liveCandle = response.data;
      if (liveCandle && liveCandle.low && liveCandle.high) {
        const candleTime = Math.floor(new Date(liveCandle.time).getTime() / 1000) as UTCTimestamp;

        const formattedCandle = {
          time: candleTime,
          open: liveCandle.open,
          high: liveCandle.high,
          low: liveCandle.low,
          close: liveCandle.close,
        };

        if (liveCandleTimeRef.current === candleTime) {
          // اگر زمان کندل تغییر نکرد، فقط به‌روزرسانی کن
          candleSeriesRef.current?.update(formattedCandle);
        } else {
          // اگر زمان تغییر کرد، کندل قبلی را حذف و فقط یکی نگه‌دار
          candleSeriesRef.current?.setData([formattedCandle]);
        }
        liveCandleTimeRef.current = candleTime;
      }
    } catch (error) {
      console.error("خطا در دریافت داده کندل لایو:", error);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      fetchLiveCandle();
    }, 1000);

    return () => clearInterval(interval);
  }, [currentSymbol, timeframe]);
  const fetchCandleData = async (symbol: string, timeframe: string) => {
    try {
      const response = await axios.get(`http://82.115.20.86:5000/api/candles`, {
        params: { symbol, timeframe },
      });

      const candleData = response.data.flatMap((item: any) =>
        JSON.parse(item.candles).map((candle: any) => ({
          time: Math.floor(new Date(candle.time).getTime() / 1000) as UTCTimestamp,
          open: candle.open,
          high: candle.high,
          low: candle.low,
          close: candle.close,
        }))
      );

      candleSeriesRef.current?.setData(candleData);
    } catch (error) {
      console.error("خطا در دریافت داده‌های کندل:", error);
    }
  };


  useEffect(() => {
    if (chartContainerRef.current) {
      chartRef.current = createChart(chartContainerRef.current, {
        width: fullscreen ? window.innerWidth : chartContainerRef.current.clientWidth || (isMobile ? 400 : 800),
        height: fullscreen ? window.innerHeight : chartContainerRef.current.clientHeight || (isMobile ? 300 : 600) - heightOffset,
        layout: {
          textColor: "#000000",
        },
        grid: {
          vertLines: { color: "#e1e1e1" },
          horzLines: { color: "#e1e1e1" },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: timeframe === "1", // نمایش ثانیه‌ها فقط در تایم‌فریم ۱ دقیقه
          tickMarkFormatter: (time: UTCTimestamp, tickMarkType: number) => {
            const date = new Date(time * 1000);
            switch (timeframe) {
              case "1":
              case "5":
              case "15":
              case "30":
                return `${date.getHours()}:${date.getMinutes()} ${date.getDate()}/${date.getMonth() + 1}`;
              case "60":
              case "240":
                return `${date.getDate()}/${date.getMonth() + 1} ${date.getFullYear()}`;
              case "D":
              case "W":
              case "M":
                return `${date.getMonth() + 1}/${date.getFullYear()}`;
              default:
                return `${date.getDate()}/${date.getMonth() + 1} ${date.getFullYear()}`;
            }
          },
        },
      });


      candleSeriesRef.current = chartRef.current.addCandlestickSeries({
        upColor: "#26a69a",
        downColor: "#ef5350",
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
        priceFormat: {
          type: "price",
          precision: determinePrecision(currentSymbol),
          minMove: Math.pow(10, -determinePrecision(currentSymbol)),
        },
      });

      fetchCandleData(currentSymbol, timeframe);
      fetchPositionDetails();

      const symbolChangeListener = (event: Event) => {
        const customEvent = event as CustomEvent<string>;
        const newSymbol = customEvent.detail;

        if (newSymbol !== currentSymbol) {
          // حذف خطوط قدیمی قبل از تغییر نماد
          slLineRef.current?.priceScale()?.applyOptions({ visible: false });
          tpLineRef.current?.priceScale()?.applyOptions({ visible: false });
          openLineRef.current?.priceScale()?.applyOptions({ visible: false });

          if (slLineRef.current) {
            chartRef.current?.removeSeries(slLineRef.current);
            slLineRef.current = undefined;
          }
          if (tpLineRef.current) {
            chartRef.current?.removeSeries(tpLineRef.current);
            tpLineRef.current = undefined;
          }
          if (openLineRef.current) {
            chartRef.current?.removeSeries(openLineRef.current);
            openLineRef.current = undefined;
          }

          setCurrentSymbol(newSymbol);
          fetchCandleData(newSymbol, timeframe);
          candleSeriesRef.current?.setData([]);
          fetchPositionDetails();;
        }
      };

      window.addEventListener("symbolChange", symbolChangeListener as EventListener);


      return () => {
        window.removeEventListener("symbolChange", symbolChangeListener);
        chartRef.current?.remove();
      };
    }
  }, [currentSymbol, showSL, showTP, showOpenPrice, timeframe, isMobile, heightOffset]);

  const handleTimeframeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setTimeframe(newValue);
    fetchCandleData(currentSymbol, newValue);
    fetchLiveCandle();  // برای بروزرسانی کندل لایو هنگام تغییر تایم‌فریم
  };
  const toggleFullscreen = () => {
    const newFullscreenState = !fullscreen;
    setFullscreen(newFullscreenState);
    onFullscreenChange?.(newFullscreenState); // اطلاع به والد
  };
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.resize(
        fullscreen ? window.innerWidth : chartContainerRef.current?.clientWidth || (isMobile ? 400 : 800),
        fullscreen ? window.innerHeight : chartContainerRef.current?.clientHeight || (isMobile ? 300 : 600) - heightOffset
      );
    }
  }, [fullscreen, isMobile, heightOffset]);
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          fullscreen ? window.innerWidth : chartContainerRef.current?.clientWidth || (isMobile ? 400 : 800),
          fullscreen ? window.innerHeight : chartContainerRef.current?.clientHeight || (isMobile ? 300 : 600) - heightOffset
        );
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [fullscreen, isMobile, heightOffset]);


  return (
    <div
      style={{
        position: fullscreen ? "fixed" : "relative",
        top: fullscreen ? 0 : "unset",
        left: fullscreen ? 0 : "unset",
        width: fullscreen ? "100vw" : "100%",
        height: fullscreen ? "100vh" : "100%",
        zIndex: fullscreen ? 1000 : "auto",
        backgroundColor: "#fff",
      }}
    >
      <button
        onClick={toggleFullscreen}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 10,
          padding: "10px",
          borderRadius: "50%",
          backgroundColor: "#002B5B",
          color: "#fff",
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
          fontSize: "16px",
        }}
        title={fullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
      >
        {fullscreen ? "×" : "⛶"}
      </button>
      {/* نمایش نماد سیمبول و آیکون */}
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderRadius: "5px",
          padding: "5px 10px",
          display: "flex",
          alignItems: "center",
          zIndex: 10,
        }}
      >
        <img
          src={`/images/${currentSymbol}.png`}
          alt={currentSymbol}
          style={{ width: "24px", height: "24px", marginRight: "10px" }}
        />
        <h3 style={{ margin: 0, fontSize: "16px" }}>{currentSymbol}</h3>
      </div>

      {/* منوی کشویی تایم فریم و Line */}
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "80px", // تغییر موقعیت برای اضافه کردن Line
          display: "flex",
          gap: "10px",
          zIndex: 10,
        }}
      >
        {/* Timeframe */}
        <Box sx={{ minWidth: 70 }}>
          <FormControl sx={{ minWidth: 70 }} size="small">
            <InputLabel id="timeframe-select-label">Timeframe</InputLabel>
            <Select
              labelId="timeframe-select-label"
              id="timeframe-select"
              value={timeframe}
              label="Timeframe"
              onChange={handleTimeframeChange}
            >
              <MenuItem value="1">1 Min</MenuItem>
              <MenuItem value="5">5 Min</MenuItem>
              <MenuItem value="15">15 Min</MenuItem>
              <MenuItem value="30">30 Min</MenuItem>
              <MenuItem value="60">1 Hour</MenuItem>
              <MenuItem value="240">4 Hours</MenuItem>
              <MenuItem value="D">Daily</MenuItem>
              <MenuItem value="W">Weekly</MenuItem>
              <MenuItem value="M">Monthly</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Line Menu */}
        <Box sx={{ minWidth: 70 }}>
          <FormControl sx={{ minWidth: 70 }} size="small">
            <InputLabel id="line-select-label">Line</InputLabel>
            <Select
              labelId="line-select-label"
              id="line-select"
              multiple
              value={[]}
              open={openLineMenu}
              onOpen={() => setOpenLineMenu(true)}
              onClose={() => setOpenLineMenu(false)}
              renderValue={() => "Select Lines"}
            >
              <MenuItem>
                <Checkbox
                  checked={tempShowSL}
                  onChange={() => setTempShowSL((prev) => !prev)}
                  sx={{ color: "red", "&.Mui-checked": { color: "red" } }}
                />
                SL
              </MenuItem>

              <MenuItem>
                <Checkbox
                  checked={tempShowTP}
                  onChange={() => setTempShowTP((prev) => !prev)}
                  sx={{ color: "green", "&.Mui-checked": { color: "green" } }}
                />
                TP
              </MenuItem>

              <MenuItem>
                <Checkbox
                  checked={tempShowOpenPrice}
                  onChange={() => setTempShowOpenPrice((prev) => !prev)}
                  sx={{ color: "black", "&.Mui-checked": { color: "black" } }}
                />
                Open Price
              </MenuItem>

              {/* دکمه OK */}
              <MenuItem>
                <button
                  onClick={() => {
                    setShowSL(tempShowSL);
                    setShowTP(tempShowTP);
                    setShowOpenPrice(tempShowOpenPrice);
                    fetchPositionDetails();
                    setOpenLineMenu(false);
                  }}
                  style={{
                    width: "100%",
                    padding: "5px",
                    backgroundColor: "#002B5B",
                    color: "#fff",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  OK
                </button>
              </MenuItem>

            </Select>
          </FormControl>
        </Box>
      </div>


      {/* چارت */}
      <div
        ref={chartContainerRef}
        style={{
          width: "100%",
          height: isMobile ? `calc(100vh - ${heightOffset}px)` : "100%", // تنظیم ارتفاع برای موبایل
        }}
      />
    </div>
  );
};

export default TradingViewWidget;

import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import TradingViewWidget from '../components/TradingViewWidget';
import PriceTable from '../components/PriceTable';
import TopBar from '../components/TopBar';
import { useMediaQuery, Box, useTheme } from '@mui/material';

const DashboardPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchTerm, setSearchTerm] = useState('');

  const [isFullscreen, setIsFullscreen] = useState(false); // مدیریت حالت Fullscreen


  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const [isTopBarVisible, setTopBarVisible] = useState(true); // همیشه باز
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile); // همیشه باز

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <TopBar
        onSearch={handleSearch}
        onMenuClick={toggleSidebar}
        isTopBarVisible={isTopBarVisible}
        setTopBarVisible={setTopBarVisible}
      />
      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', marginTop: '64px' }}>
        <Sidebar
          open={sidebarOpen}
          onClose={toggleSidebar}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          {/* محتوا زیر تاپ بار */}
          <Box
            sx={{
              flexGrow: 1,
              marginTop: isMobile ? '64px' : '0', // در دسکتاپ فاصله حذف شود تا جدول بالا بیاید
              display: 'flex',
              flexDirection: 'column',
              height: '100vh', // ارتفاع کل صفحه
              overflow: 'auto', // اجازه اسکرول دادن فقط به محتوا
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflow: 'auto',
                paddingTop: { xs: isTopBarVisible ? '110px' : '1px', sm: isTopBarVisible ? '16px' : '1px' }, // تغییر فاصله براساس وضعیت تاپ بار
                transition: 'padding-top 0.3s ease-in-out', // اضافه کردن انیمیشن برای تغییر نرم ارتفاع
              }}
            >
              <PriceTable searchTerm={searchTerm} />
            </Box>

            <Box
              sx={{
                flex: 1,
                overflow: 'hidden',
                borderTop: '1px solid #ccc',
              }}
            >
              <TradingViewWidget
                onFullscreenChange={(isFullscreen: boolean) => {
                  setIsFullscreen(isFullscreen);
                  if (!isFullscreen) {
                    setSidebarOpen(true);
                  }
                }}
              />

            </Box>
          </Box>
        </Box>
      </Box>
    </Box>

  );
};

export default DashboardPage;

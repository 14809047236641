import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  IconButton,
  AppBar,
  Box,
  Typography,
  ListItemIcon,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CloseIcon from '@mui/icons-material/Close';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
interface SidebarProps {
  open: boolean; // کنترل باز بودن
  onClose: () => void; // کنترل بسته شدن
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    onClose(); // بستن سایدبار پس از ناوبری
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
    window.location.reload();
    onClose(); // بستن سایدبار
  };

  const drawerContent = (
    <div>
      <Toolbar />
      <List sx={{ textAlign: 'center' }}>
        {[
          { text: 'Home', icon: <HomeIcon fontSize="small" sx={{ color: '#FFFFFF' }} />, path: '/dashboard' },
          { text: 'Orders', icon: <ShoppingCartIcon fontSize="small" sx={{ color: '#FFFFFF' }} />, path: '/OrdersPage' },
          { text: 'Open Positions', icon: <TrendingUpIcon fontSize="small" sx={{ color: '#FFFFFF' }} />, path: '/open-positions' },
          { text: 'Close Positions', icon: <CloseIcon fontSize="small" sx={{ color: '#FFFFFF' }} />, path: '/close-positions' },
          { text: 'Strategies', icon: <QuestionAnswerIcon fontSize="small" sx={{ color: '#FFFFFF' }} />, path: '/strategies' },
          { text: 'Funds', icon: <AccountBalanceWalletIcon fontSize="small" sx={{ color: '#FFFFFF' }} />, path: '/funds' },
          { text: 'Log Out', icon: <LogoutIcon fontSize="small" sx={{ color: '#FFFFFF' }} />, action: handleLogout },
        ].map((item, index) => (
          <ListItem key={index} disablePadding sx={{ flexDirection: 'column' }}>
            <ListItemButton
              onClick={() => (item.path ? handleNavigation(item.path) : item.action && item.action())}
              sx={{ flexDirection: 'column' }}
            >
              <ListItemIcon sx={{ minWidth: 'auto', mb: 1 }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  fontSize: '0.8rem',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  color: '#FFFFFF',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          position: 'fixed',
          top: '15%',
          left: open ? '80px' : '0px', // جابه‌جایی بر اساس حالت Sidebar
          transform: 'translateY(-50%)', // تنظیم مرکزیت عمودی
          zIndex: 1200,
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          transition: 'left 0.3s ease-in-out', // اضافه کردن انیمیشن
        }}
      >
        <IconButton
          color="primary"
          onClick={onClose}
          sx={{
            padding: '8px',
            '&:hover': { backgroundColor: '#f0f0f0' },
          }}
        >
          {open ? (
            <KeyboardDoubleArrowLeftIcon />
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </IconButton>
      </Box>

      {/* Permanent Sidebar for Desktop */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: open ? 'block' : 'none', sm: open ? 'block' : 'none' },
          width: open ? 80 : 0,
          flexShrink: 0,
          overflow: 'hidden',
          transition: 'width 0.3s ease-in-out',
          marginTop: '64px', // اضافه کردن فاصله از بالا معادل ارتفاع TopBar
          '& .MuiDrawer-paper': {
            width: open ? 80 : 0,
            boxSizing: 'border-box',
            backgroundColor: '#002B5B',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            overflow: 'hidden',
            marginTop: '64px', // تنظیم فاصله برای Paper
            height: 'calc(100% - 64px)', // ارتفاع سایدبار از زیر تاپ‌بار تا پایین صفحه
          },
        }}
        open={open}
      >


        {drawerContent}
      </Drawer>

      {/* Temporary Sidebar for Mobile */}
      <Drawer
        variant="temporary"
        open={open}
        onClose={onClose}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            marginTop: '64px',
            width: 80,
            boxSizing: 'border-box',
            backgroundColor: '#002B5B',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export default Sidebar;

import React, { useState } from 'react';
import { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  Typography,
  IconButton,
  TextField,
  Switch,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';



const CustomSwitch = ({ label, checked, onChange }: { label: string; checked: boolean; onChange: () => void }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
    <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
    <Switch checked={checked} onChange={onChange} />
  </Box>
);

interface TradeModalProps {
  open: boolean;
  onClose: () => void;
  symbol: string;
  change: string;
  value: string;
  type: 'Buy' | 'Sell';
}
interface PriceData {
  id: number;
  symbol: string;
  ask: string;
  bid: string;
  balance: string | null;
  high: string;
  low: string;
  close: string;
  open: string;
  volume: string;
  change_percent: string | null;
  allowed: number;
}


const TradeModal: React.FC<TradeModalProps> = ({ open, onClose, symbol, change, value, type }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [quantity, setQuantity] = useState('');
  const [lot, setLot] = useState('0.00');
  const [amount, setAmount] = useState('0.00');
  const [prices, setPrices] = useState<{
    buy: string;
    sell: string;
    eurUsdAsk: string;
    eurUsdBid: string;
    gbpUsdAsk: string;
    gbpUsdBid: string;
  }>({
    buy: '0.00',
    sell: '0.00',
    eurUsdAsk: '0.00',
    eurUsdBid: '0.00',
    gbpUsdAsk: '0.00',
    gbpUsdBid: '0.00',
  });

  const validateInputs = (): boolean => {
    const numericQuantity = parseFloat(quantity);
    const numericAtPrice = parseFloat(atPrice);
    const numericBuyPrice = parseFloat(prices.buy);
    const numericSellPrice = parseFloat(prices.sell);

    if (!quantity || isNaN(numericQuantity)) {
      alert('Quantity (Contract) is required.');
      return false;
    }
    if (pendingOrder && atPrice && pendingType) {
      if (pendingType === "Stop" && type === "Buy" && numericAtPrice <= numericBuyPrice) {
        alert('For a Pending Order with Stop and Buy, "At Price" must be less than "Buy at" price.');
        return false;
      }
      if (pendingType === "Stop" && type === "Sell" && numericAtPrice >= numericSellPrice) {
        alert('For a Pending Order with Stop and Sell, "At Price" must be greater than "Sell at" price.');
        return false;
      }
      if (pendingType === "Limit" && type === "Buy" && numericAtPrice >= numericBuyPrice) {
        alert('For a Pending Order with Limit and Buy, "At Price" must be greater than "Buy at" price.');
        return false;
      }
      if (pendingType === "Limit" && type === "Sell" && numericAtPrice <= numericSellPrice) {
        alert('For a Pending Order with Limit and Sell, "At Price" must be less than "Sell at" price.');
        return false;
      }
    }
    if (takeProfit && takeProfitValue && !isNaN(parseFloat(takeProfitValue))) {
      const numericTakeProfit = parseFloat(takeProfitValue);
      if (pendingOrder) {
        if (type === "Buy" && numericTakeProfit <= numericAtPrice) {
          alert('For Pending Order with Take Profit and Buy, "Take Profit" must be greater than "At Price".');
          return false;
        }
        if (type === "Sell" && numericTakeProfit >= numericAtPrice) {
          alert('For Pending Order with Take Profit and Sell, "Take Profit" must be less than "At Price".');
          return false;
        }
      } else {
        if (type === "Buy" && numericTakeProfit <= numericBuyPrice) {
          alert('For Market Order with Take Profit and Buy, "Take Profit" must be greater than "Buy at" price.');
          return false;
        }
        if (type === "Sell" && numericTakeProfit >= numericSellPrice) {
          alert('For Market Order with Take Profit and Sell, "Take Profit" must be less than "Sell at" price.');
          return false;
        }
      }
    }

    if (stopLoss && stopLossValue && !isNaN(parseFloat(stopLossValue))) {
      const numericStopLoss = parseFloat(stopLossValue);
      if (pendingOrder) {
        if (type === "Buy" && numericStopLoss >= numericAtPrice) {
          alert('For Pending Order with Stop Loss and Buy, "Stop Loss" must be less than "At Price".');
          return false;
        }
        if (type === "Sell" && numericStopLoss <= numericAtPrice) {
          alert('For Pending Order with Stop Loss and Sell, "Stop Loss" must be greater than "At Price".');
          return false;
        }
      } else {
        if (type === "Buy" && numericStopLoss >= numericBuyPrice) {
          alert('For Market Order with Stop Loss and Buy, "Stop Loss" must be less than "Buy at" price.');
          return false;
        }
        if (type === "Sell" && numericStopLoss <= numericSellPrice) {
          alert('For Market Order with Stop Loss and Sell, "Stop Loss" must be greater than "Sell at" price.');
          return false;
        }
      }
    }

    switch (symbol) {
      case 'BTCUSD':
      case 'ETHUSD':
        if (numericQuantity < 0.01 || !/^\d+(\.\d{1,2})?$/.test(quantity)) {
          alert('Enter valid quantity (for example 0.01, 1.00, 1.01, etc.).');
          return false;
        }
        break;

      case 'US30':
      case 'UT100':
      case 'US500':
        if (numericQuantity < 0.1 || !/^\d+(\.\d{1})?$/.test(quantity)) {
          alert('Enter valid quantity (for example 0.1, 1.0, 1.9, etc.).');
          return false;
        }
        break;

      case 'XAGUSD...':
      case 'XAUUSD...':
        if (numericQuantity < 1 || !/^\d+$/.test(quantity)) {
          alert('Enter valid quantity (1, 2, 3, etc.).');
          return false;
        }
        break;

      case 'USDCHF...':
      case 'USDJPY...':
      case 'EURNZD...':
      case 'EURJPY...':
      case 'GBPJPY...':
      case 'EURUSD...':
      case 'GBPUSD...':
        if (numericQuantity < 1000 || numericQuantity % 1000 !== 0) {
          alert('Enter valid quantity (must be 1000, 2000, 3000, etc.).');
          return false;
        }
        break;

      default:
        alert('Invalid symbol.');
        return false;
    }

    if (!strategies.some((s) => s.strategy_name === selectedStrategy)) {
      alert('Please select a valid strategy.');
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const selectedPrice = pricesData.find((item) => item.symbol === 'XAUUSD...');

    if (selectedPrice && selectedPrice.allowed === 0) {
      alert('Market Is Closed');
      return; // جلوگیری از ادامه عملیات
    }
    const topBarMargin = parseFloat(localStorage.getItem('topBarMargin') || '0'); // مقدار Margin از Local Storage
    const numericRequiredMargin = parseFloat(requiredMargin);

    if (numericRequiredMargin > topBarMargin) {
      alert('Insufficient margin! Required margin exceeds available margin.');
      return;
    }

    if (validateInputs()) {
      submitOrder();
    }
  };

  const [pendingType, setPendingType] = useState("Stop");

  const submitOrder = async () => {
    if (!validateInputs()) return;
    if (!userId) {
      alert("User ID not found. Please log in again.");
      return;
    }

    const payload: any = {
      symbol,
      lot,
      type: pendingOrder
        ? pendingType === "Limit"
          ? type === "Buy"
            ? 2
            : 3
          : type === "Buy"
            ? 4
            : 5
        : type === "Buy"
          ? 0
          : 1,
      ordertype: pendingOrder ? 1 : 0,
      price: atPrice || null,
      sl: stopLossValue || "0",
      tp: takeProfitValue || "0",
      description: null,
      ordertime: new Date().toISOString().slice(0, 19).replace("T", " "),
      state: "create",
      user_id: userId, // باید مقدار آن از session یا token استخراج شود
      requiredmargin: requiredMargin,
      strategy_name: selectedStrategy,
    };

    // پیدا کردن `q_table_id` مرتبط با استراتژی انتخاب‌شده
    const strategy = strategies.find((s) => s.strategy_name === selectedStrategy);
    if (strategy && typeof strategy.id === "number") {
      payload.q_table_id = strategy.id;
    } else {
      alert("Selected strategy not found!");
      return;
    }
    console.log("Payload being sent:", payload);
    try {
      const response = await fetch("http://82.115.20.86:5000/api/orders", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const responseData = await response.json();
      console.log("Response from server:", responseData);

      if (response.ok) {
        alert("Order submitted successfully!");
        onClose(); // بستن مودال پس از ثبت
      } else {
        alert("Failed to submit order");
      }
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };
  const [pricesData, setPricesData] = useState<PriceData[]>([]);

  const fetchPrices = async () => {
    try {
      const response = await fetch('http://82.115.20.86:5000/api/prices');
      const data = await response.json();
      setPricesData(data);
      const currentSymbol = data.find((item: any) => item.symbol === symbol);
      const eurUsd = data.find((item: any) => item.symbol === 'EURUSD...');
      const gbpUsd = data.find((item: any) => item.symbol === 'GBPUSD...');

      setPrices({
        buy: currentSymbol?.ask || '0.00',
        sell: currentSymbol?.bid || '0.00',
        eurUsdAsk: eurUsd?.ask || '0.00',
        eurUsdBid: eurUsd?.bid || '0.00',
        gbpUsdAsk: gbpUsd?.ask || '0.00',
        gbpUsdBid: gbpUsd?.bid || '0.00',
      });
    } catch (error) {
      console.error('Error fetching prices:', error);
    }
  };

  // تابع برای محاسبه مقدار Amount (USD)
  const calculateAmount = (lot: string, symbol: string, value: string): string => {
    const numericLot = parseFloat(lot);
    const numericValue = parseFloat(value);
    const numericBuy = parseFloat(prices.buy);
    const numericSell = parseFloat(prices.sell);
    const numericEurUsd = parseFloat(type === 'Buy' ? prices.eurUsdAsk : prices.eurUsdBid);
    const numericGbpUsd = parseFloat(type === 'Buy' ? prices.gbpUsdAsk : prices.gbpUsdBid);

    if (isNaN(numericLot) || isNaN(numericValue)) return '0.00';

    if (['BTCUSD', 'ETHUSD', 'US30', 'UT100', 'US500'].includes(symbol)) {
      return (numericLot * 100000).toFixed(2);
    } else if (symbol === 'XAUUSD...') {
      return (numericLot * 100 * (type === 'Buy' ? numericBuy : numericSell)).toFixed(2);
    }
    else if (symbol === 'XAGUSD...') {
      return (numericLot * 5000 * (type === 'Buy' ? numericBuy : numericSell)).toFixed(2);
    }
    else if (['GBPUSD...', 'EURUSD...'].includes(symbol)) {
      return (numericLot * 100000 * (type === 'Buy' ? numericBuy : numericSell)).toFixed(2);
    } else if (['USDCHF...', 'USDJPY...'].includes(symbol)) {
      return (numericLot * 100000).toFixed(2);
    } else if (symbol === 'EURNZD...' || symbol === 'EURJPY...') {
      return (numericLot * 100000 * numericEurUsd).toFixed(2);
    } else if (symbol === 'GBPJPY...') {
      return (numericLot * 100000 * numericGbpUsd).toFixed(2);
    }
    return '0.00';
  };
  const formatPrice = (price: string, symbol: string): string => {
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) return '0.00';

    if (['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(symbol)) {
      return numericPrice.toFixed(2); // دو رقم اعشار
    } else if (['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(symbol)) {
      return numericPrice.toFixed(5); // پنج رقم اعشار
    } else if (['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(symbol)) {
      return numericPrice.toFixed(3); // سه رقم اعشار
    } else if (['XAGUSD...'].includes(symbol)) {
      return numericPrice.toFixed(4); // سه رقم اعشار
    }
    return numericPrice.toFixed(2); // پیش‌فرض دو رقم اعشار
  };
  // تابع برای محاسبه مقدار Lot
  const calculateLot = (quantity: string, symbol: string): string => {
    const numericQuantity = parseFloat(quantity);
    if (isNaN(numericQuantity)) return '0.00';

    if (
      [
        'USDCHF...',
        'USDJPY...',
        'EURNZD...',
        'EURJPY...',
        'GBPJPY...',
        'EURUSD...',
        'GBPUSD...',
      ].includes(symbol)
    ) {
      return (numericQuantity / 100000).toFixed(2);
    } else if (symbol === 'XAUUSD...') {
      return (numericQuantity / 100).toFixed(2);
    } else if (symbol === 'XAGUSD...') {
      return (numericQuantity / 5000).toFixed(2);
    } else if (
      ['BTCUSD', 'ETHUSD', 'US30', 'UT100', 'US500'].includes(symbol)
    ) {
      return numericQuantity.toFixed(2);
    }
    return '0.00';
  };
  const [takeProfitValue, setTakeProfitValue] = useState('');
  const [stopLossValue, setStopLossValue] = useState('');
  const [atPrice, setAtPrice] = useState('');

  const [selectedStrategy, setSelectedStrategy] = useState('');

  const [rateMargin, setRateMargin] = useState('0.00');
  const [requiredMargin, setRequiredMargin] = useState('0.00');
  const getUserId = () => {
    const token = localStorage.getItem("token");
    if (!token) return null;

    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.id;
    } catch {
      return null;
    }
  };

  const userId = getUserId();

  // تابع برای دریافت rate_margin
  const fetchRateMargin = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const payload = JSON.parse(atob(token.split('.')[1])); // استخراج userId از توکن
      const userId = payload.id;

      const response = await fetch(`http://82.115.20.86:5000/api/users/${userId}/rate-margin`);
      const data = await response.json();

      setRateMargin(data.rateMargin || '0.00');
    } catch (error) {
      console.error('Error fetching rate margin:', error);
    }
  };

  // اجرا در هنگام باز شدن مودال
  useEffect(() => {
    if (open) {
      fetchRateMargin();
    }
  }, [open]);

  // محاسبه Required Margin
  useEffect(() => {
    const numericAmount = parseFloat(amount);
    const numericRateMargin = parseFloat(rateMargin);

    if (!isNaN(numericAmount) && !isNaN(numericRateMargin)) {
      const margin = (numericAmount * numericRateMargin) / 100;
      setRequiredMargin(margin.toFixed(2));
    }
  }, [amount, rateMargin]);
  const [takeProfit, setTakeProfit] = useState(false);
  const [stopLoss, setStopLoss] = useState(false);
  const [pendingOrder, setPendingOrder] = useState(false);
  const [strategies, setStrategies] = useState<Array<{ strategy_name: string; id: number }>>([]);

  const fetchStrategies = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;

      const payload = JSON.parse(atob(token.split(".")[1]));
      const userId = payload.id;

      const response = await fetch(
        `http://82.115.20.86:5000/api/strategies/filtered?userId=${userId}&symbol=${symbol}`
      );
      const data = await response.json();

      if (data && Array.isArray(data)) {
        console.log("Fetched strategies:", data);
        setStrategies(data.map((item: any) => ({ strategy_name: item, id: Math.random() }))); // نمونه‌ای برای ID
      } else {
        console.warn("Invalid data received:", data);
        setStrategies([]);
      }
    } catch (error) {
      console.error("Error fetching strategies:", error);
    }
  };
  // اجرا در زمان تغییر symbol
  useEffect(() => {
    if (open) {
      fetchStrategies();
      fetchPrices();
    }

    const interval = setInterval(() => {
      if (open) fetchPrices();
    }, 1000); // بروز رسانی هر 1 ثانیه

    return () => clearInterval(interval);
  }, [symbol, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          width: isMobile ? '100%' : '350px', // کاهش عرض در دسکتاپ
          height: '100%',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          margin: 0,
          borderRadius: isMobile ? 0 : '8px', // گوشه‌های گرد فقط در دسکتاپ
          fontFamily: 'inherit', // بازگرداندن فونت به حالت قبلی
          padding: isMobile ? 0 : '10px', // کاهش فضای داخلی در دسکتاپ
        },
      }}
    >
      {/* هدر مودال */}
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #ddd',
        }}
      >
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ textAlign: 'center', flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <img
              src={`/images/${symbol}.png`}
              alt={symbol}
              style={{ width: '28px', height: '28px', borderRadius: '50%' }} // کوچک‌تر کردن آیکون
            />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{symbol}</Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ color: 'black', fontWeight: 'bold', marginTop: 1 }}>
            {type} at: {formatPrice(type === 'Buy' ? prices.buy : prices.sell, symbol)}
          </Typography>
          {['EURNZD...', 'EURJPY...', 'GBPJPY...'].includes(symbol) && false && (
            <>
              <Typography variant="subtitle2" sx={{ color: 'black', fontWeight: 'bold' }}>
                EURUSD {type}: {type === 'Buy' ? prices.eurUsdAsk : prices.eurUsdBid}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'black', fontWeight: 'bold' }}>
                GBPUSD {type}: {type === 'Buy' ? prices.gbpUsdAsk : prices.gbpUsdBid}
              </Typography>
            </>
          )}

        </Box>
        <Typography
          variant="subtitle2"
          sx={{
            color: parseFloat(change) > 0 ? 'green' : parseFloat(change) < 0 ? 'red' : 'black', fontWeight: 'bold'
          }}
        >
          Change: {parseFloat(change).toFixed(2)}
        </Typography>
      </Box>

      {/* محتوای مودال */}
      <Box sx={{ padding: 2, overflowY: 'auto' }}>
        <Typography variant="subtitle1" sx={{ marginBottom: 1, fontWeight: 'bold' }}>
          Quantity (Contract):
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '6px', // کاهش شعاع گردی
            padding: '5px 8px',
            bgcolor: '#f9f9f9',
            marginBottom: 2,
            fontWeight: 'bold',
          }}
        >
          <TextField
            fullWidth
            type="text"
            value={quantity}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                setQuantity(value);
                const calculatedLot = calculateLot(value, symbol); // محاسبه مقدار Lot
                setLot(calculatedLot);
                setAmount(calculateAmount(calculatedLot, symbol, type === 'Buy' ? prices.buy : prices.sell));

              }
            }}
            variant="standard"
            placeholder="Enter quantity"
            inputProps={{
              inputMode: 'decimal',
              pattern: '^[0-9]*\\.?[0-9]*$',
            }}
            sx={{
              border: 'none',
              outline: 'none',
              fontSize: '0.9rem',
            }}
          />

        </Box>

        <Typography sx={{ fontWeight: 'bold' }}>Lot: {lot}</Typography>
        <Typography sx={{ fontWeight: 'bold' }}>Amount (USD): {amount}</Typography>
        <Typography sx={{ fontWeight: 'bold' }}>Required Margin: {requiredMargin}$</Typography>

        <Typography sx={{ marginTop: 2, fontWeight: 'bold' }}>Select Strategy:</Typography>
        <Select
          fullWidth
          value={selectedStrategy}
          onChange={(e) => setSelectedStrategy(e.target.value as string)}
          sx={{
            marginBottom: 2,
            height: '40px', // تنظیم ارتفاع
            fontSize: '0.875rem', // تنظیم سایز فونت
            padding: '0 8px', // تنظیم فضای داخلی
          }}
        >
          {strategies.map((strategy, index) => (
            <MenuItem key={index} value={strategy.strategy_name} sx={{ color: 'black' }}>
              {strategy.strategy_name}
            </MenuItem>
          ))}
          {strategies.length === 0 && (
            <MenuItem disabled sx={{ color: 'black' }}>No strategies available</MenuItem>
          )}
        </Select>

        <CustomSwitch
          label="Take Profit"
          checked={takeProfit}
          onChange={() => setTakeProfit(!takeProfit)}
        />
        {takeProfit && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: '6px',
              padding: '5px 8px',
              bgcolor: '#f9f9f9',
              marginBottom: 2,
              fontWeight: 'bold',
            }}
          >
            <TextField
              fullWidth
              type="text"
              value={takeProfitValue}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  setTakeProfitValue(value);
                }
              }}
              variant="standard"
              placeholder="Enter Take Profit"
            />


          </Box>
        )}

        <CustomSwitch
          label="Stop Loss"
          checked={stopLoss}
          onChange={() => setStopLoss(!stopLoss)}
        />
        {stopLoss && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: '6px',
              padding: '5px 8px',
              bgcolor: '#f9f9f9',
              marginBottom: 2,
              fontWeight: 'bold',
            }}
          >
            <TextField
              fullWidth
              type="text"
              value={stopLossValue}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  setStopLossValue(value);
                }
              }}
              variant="standard"
              placeholder="Enter Stop Loss"
            />


          </Box>
        )}

        <CustomSwitch
          label="Pending Order"
          checked={pendingOrder}
          onChange={() => setPendingOrder(!pendingOrder)}
        />
        {pendingOrder && (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>Pending Type:</Typography>
            <Select
              fullWidth
              value={pendingType}
              onChange={(e) => setPendingType(e.target.value)}
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="Stop">Stop</MenuItem>
              <MenuItem value="Limit">Limit</MenuItem>
            </Select>

            <Typography sx={{ fontWeight: 'bold' }}>At Price:</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #ccc',
                borderRadius: '6px',
                padding: '5px 8px',
                bgcolor: '#f9f9f9',
                marginBottom: 2,
              }}
            >
              <TextField
                fullWidth
                type="text"
                value={atPrice}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                    setAtPrice(value);
                  }
                }}
                variant="standard"
                placeholder="Enter Price"
              />


            </Box>
          </>
        )}
      </Box>

      {/* دکمه پایین */}
      <Box sx={{ padding: 2, borderTop: '1px solid #ddd' }}>
        <Button
          fullWidth
          variant="contained"
          color={type === 'Buy' ? 'success' : 'error'}
          onClick={handleSubmit}
        >
          {type}
        </Button>
      </Box>
    </Dialog>
  );
};

export default TradeModal;

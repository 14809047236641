import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import StrategyTable from '../components/StrategyTable';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    Checkbox,
    FormControlLabel,
    useMediaQuery,
    useTheme,
    FormControl,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';

const StrategiesPage: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
    const [isTopBarVisible, setTopBarVisible] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [strategyData, setStrategyData] = useState<{
        strategyName: string;
        instrumentSymbol: string;
        tradeStyle: string;
        riskRewardRatio: string;
        capitalAllocated: string;
        marketCondition: string;

        timeframe: string[]; // نوع داده به آرایه‌ای از رشته‌ها تغییر کرد
        comments: string;
    }>({
        strategyName: '',
        instrumentSymbol: '',
        tradeStyle: '',
        riskRewardRatio: '',
        capitalAllocated: '',
        marketCondition: '',

        timeframe: [], // مقدار اولیه
        comments: '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const tokenExpiry = localStorage.getItem('token_expiry');

        if (!token || !tokenExpiry || new Date(tokenExpiry) < new Date()) {
            alert("Your session has expired. Please log in again.");
            localStorage.removeItem('token');
            localStorage.removeItem('token_expiry');
            navigate('/');
            return;
        }
    }, [navigate]);


    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);
    const handleInputChange = (field: string, value: any) => {
        setStrategyData((prev) => ({ ...prev, [field]: value }));
    };
    const handleSubmit = async () => {
        const { strategyName, instrumentSymbol, tradeStyle, riskRewardRatio, capitalAllocated, marketCondition, timeframe, comments } = strategyData;

        if (!strategyName || !instrumentSymbol || !tradeStyle || !riskRewardRatio || !capitalAllocated || !marketCondition || timeframe.length === 0 || !comments) {
            alert("All fields are required!");
            return;
        }

        try {
            const token = localStorage.getItem("token");
            const userInfoResponse = await fetch("http://82.115.20.86:5000/api/users/info", {
                headers: { Authorization: `Bearer ${token}` },
            });

            const userInfo = await userInfoResponse.json();
            const { id: userId, username } = userInfo;

            const strategyIdResponse = await fetch(`http://82.115.20.86:5000/api/strategies/last-id?user_id=${userId}`);
            const { lastStrategyId } = await strategyIdResponse.json();
            const newStrategyId = lastStrategyId !== null ? lastStrategyId + 1 : 0;

            const formData = new FormData();
            formData.append("username", username);
            formData.append("strategyName", strategyName);
            formData.append("strategyId", newStrategyId.toString());
            formData.append("strategyState", "active");
            formData.append("instrumentSymbol", instrumentSymbol);
            formData.append("tradeStyle", tradeStyle);
            formData.append("timeframeAnalysis", timeframe.join(","));
            formData.append("marketConditions", marketCondition);
            formData.append("riskRewardRatio", riskRewardRatio);
            formData.append("capitalAllocated", capitalAllocated);
            formData.append("comments", comments);
            formData.append("userId", userId.toString());
            formData.append("hidden", "0");

            if (selectedFile) {
                formData.append("file", selectedFile);
            }

            const response = await fetch("http://82.115.20.86:5000/api/strategies/upload", {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                alert("Strategy and file uploaded successfully!");
                closeDialog();
                refreshStrategyTable();
            } else {
                alert("Failed to upload strategy.");
            }
        } catch (error) {
            console.error("Error submitting strategy:", error);
            alert("An error occurred. Please try again.");
        }
    };

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [refresh, setRefresh] = useState(false);

    const refreshStrategyTable = () => {
        setRefresh((prev) => !prev); // وضعیت را تغییر می‌دهد و باعث رفرش شدن جدول می‌شود.
    };
    const [filterMode, setFilterMode] = useState<'current' | 'old' | null>('current');
    useEffect(() => {
        setFilterMode('current'); // پیش‌فرض فیلتر روی current
    }, []);



    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <Sidebar open={sidebarOpen} onClose={toggleSidebar} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    padding: 2,
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar onSearch={() => { }} onMenuClick={toggleSidebar} isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: { xs: 25, sm: 18 },
                        gap: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={openDialog}
                        sx={{
                            backgroundColor: '#4CAF50',
                            color: '#fff',
                            padding: '10px 20px',
                            fontSize: '1.2rem',
                            textTransform: 'none',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                backgroundColor: '#45a049',
                            },
                        }}
                    >
                        Add Strategy
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setFilterMode('current');
                            refreshStrategyTable(); // اطمینان از رفرش جدول
                        }}
                        sx={{
                            backgroundColor: '#2196F3',
                            color: '#fff',
                            padding: '10px 20px',
                            fontSize: '1.2rem',
                            textTransform: 'none',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                backgroundColor: '#1976D2',
                            },
                        }}
                    >
                        Current Strategy
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setFilterMode('old');
                            refreshStrategyTable(); // اطمینان از رفرش جدول
                        }}
                        sx={{
                            backgroundColor: '#FF5722',
                            color: '#fff',
                            padding: '10px 20px',
                            fontSize: '1.2rem',
                            textTransform: 'none',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                backgroundColor: '#E64A19',
                            },
                        }}
                    >
                        Old Strategy
                    </Button>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: 2, // فاصله کم بین دکمه و جدول
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100vh - 64px)', // محاسبه ارتفاع با توجه به ارتفاع تاپ‌بار
                        overflow: 'hidden', // جلوگیری از اسکرول اضافی
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflow: 'auto',
                            padding: 0, // حذف فضای اضافی
                        }}
                    >
                        <StrategyTable refresh={refresh} filterMode={filterMode} />

                    </Box>
                </Box>
                <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
                    <DialogTitle>Questions</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 2 }}>
                            <TextField
                                label="Strategy Name"
                                fullWidth
                                value={strategyData.strategyName}
                                onChange={(e) => handleInputChange('strategyName', e.target.value)}
                            />
                            <FormControl fullWidth>
                                <InputLabel>Instrument Symbol</InputLabel>
                                <Select
                                    value={strategyData.instrumentSymbol}
                                    onChange={(e) => handleInputChange('instrumentSymbol', e.target.value)}
                                >
                                    {['BTCUSD', 'ETHUSD', 'EURUSD...', 'GBPUSD...', 'XAUUSD...', 'XAGUSD...', 'USDCHF...', 'USDJPY...', 'EURNZD...', 'EURJPY...', 'GBPJPY...', 'US30', 'UT100', 'US500'].map((symbol) => (
                                        <MenuItem key={symbol} value={symbol}>
                                            {symbol}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel>Trade Style</InputLabel>
                                <Select
                                    value={strategyData.tradeStyle}
                                    onChange={(e) => handleInputChange('tradeStyle', e.target.value)}
                                >
                                    {['Scalping', 'Day Trading', 'Swing Trading', 'Position Trading'].map((style) => (
                                        <MenuItem key={style} value={style}>
                                            {style}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Risk/Reward Ratio"
                                fullWidth
                                value={strategyData.riskRewardRatio}
                                onChange={(e) => handleInputChange('riskRewardRatio', e.target.value)}
                            />
                            <TextField
                                label="Amount of Capital Allocated"
                                fullWidth
                                value={strategyData.capitalAllocated}
                                onChange={(e) => handleInputChange('capitalAllocated', e.target.value)}
                            />
                            <FormControl fullWidth>
                                <InputLabel>Market Conditions</InputLabel>
                                <Select
                                    value={strategyData.marketCondition}
                                    onChange={(e) => handleInputChange('marketCondition', e.target.value)}
                                >
                                    {['Bullish', 'Bearish', 'Ranging', 'Volatile', 'Stable'].map((condition) => (
                                        <MenuItem key={condition} value={condition}>
                                            {condition}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                                Timeframe Of Analysis
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                                {['1min', '5min', '15min', '30min', '1hour', '4hour', 'Daily', 'Weekly'].map((time) => (
                                    <Button
                                        key={time}
                                        variant={strategyData.timeframe.includes(time) ? 'contained' : 'outlined'}
                                        onClick={() =>
                                            handleInputChange(
                                                'timeframe',
                                                strategyData.timeframe.includes(time)
                                                    ? strategyData.timeframe.filter((t) => t !== time)
                                                    : [...strategyData.timeframe, time]
                                            )
                                        }
                                        sx={{ textTransform: 'none' }}
                                    >
                                        {time}
                                    </Button>
                                ))}
                            </Box>
                            <TextField
                                label="Comments"
                                multiline
                                rows={1}
                                fullWidth
                                value={strategyData.comments}
                                onChange={(e) => handleInputChange('comments', e.target.value)}
                            />
                            <Button
                                variant="contained"
                                startIcon={<DownloadIcon />}
                                sx={{
                                    textTransform: 'none',
                                    padding: '10px 20px', // مشابه دکمه Upload
                                    borderRadius: '8px',
                                    fontSize: '1rem', // مشابه دکمه Upload
                                    backgroundColor: '#4CAF50',
                                    color: '#fff',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                    width: '100%', // تنظیم طول برابر
                                    '&:hover': {
                                        backgroundColor: '#45a049',
                                    },
                                    alignSelf: 'flex-start', // مشابه دکمه Upload
                                }}
                                href="http://82.115.20.86:3000/images/User_Manual.pdf"
                                download
                            >
                                Download The Manual
                            </Button>



                            <Button
                                variant="contained"
                                startIcon={<UploadFileIcon />}
                                component="label"
                                sx={{
                                    backgroundColor: '#4CAF50',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    fontSize: '1rem',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                    '&:hover': {
                                        backgroundColor: '#45A049',
                                    },
                                }}
                            >
                                Upload Strategy Document
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => {
                                        const file = e.target.files ? e.target.files[0] : null;
                                        if (file) {
                                            setSelectedFile(file);
                                            console.log('Selected file:', file.name);
                                        }
                                    }}
                                />
                            </Button>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={closeDialog} color="secondary" variant="outlined">
                            Close
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleSubmit}>
                            Submit
                        </Button>

                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default StrategiesPage;

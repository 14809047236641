import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import axios from 'axios';
import Joi from 'joi';
interface Order {
    id: number;
    symbol: string;
    lot: number;
    type: string;
    price: number;
    currentPrice: number;
    sl: number;
    tp: number;
    orderTime: string;
    ask?: number; // در صورت وجود
    bid?: number; // در صورت وجود
}

const OrdersPage: React.FC = () => {

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<any>(null);
    const [orders, setOrders] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
    const navigate = useNavigate();
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getUserId = () => {
        const token = localStorage.getItem("token");
        if (!token) return null;

        try {
            const payload = JSON.parse(atob(token.split(".")[1]));
            return payload.id;
        } catch {
            return null;
        }
    };

    const fetchOrders = async () => {
        try {
            setLoading(true);
            const userId = getUserId(); // دریافت userId از توکن
            if (!userId) {
                console.error("User ID is not available.");
                alert("User ID is not available. Please log in again.");
                navigate('/'); // هدایت به صفحه لاگین
                return;
            }


            const token = localStorage.getItem("token"); // دریافت توکن از localStorage
            const response = await axios.get(`http://82.115.20.86:5000/api/orders`, {
                params: { userId },
                headers: {
                    Authorization: `Bearer ${token}`, // اضافه کردن هدر Authorization
                },
            });

            const data: Order[] = response.data.map((order: any) => ({
                ...order,
                currentPrice: parseFloat(
                    order.type === '2' || order.type === '4' ? order.ask : order.bid
                ) || 0, // مقدار پیش‌فرض 0 اگر مقدار `ask` یا `bid` نامعتبر باشد
                price: parseFloat(order.price) || 0, // اطمینان از مقدار عددی برای price
                type:
                    order.type === '2'
                        ? 'Buy Limit'
                        : order.type === '3'
                            ? 'Sell Limit'
                            : order.type === '4'
                                ? 'Buy Stop'
                                : 'Sell Stop',
                orderTime: order.ordertime,
            }));
            setOrders(data);
            if (modifyOrder) {
                const updatedOrder = data.find((order: Order) => order.id === modifyOrder.id);
                if (updatedOrder) {
                    setModifyOrder(updatedOrder); // به‌روزرسانی سفارش فعلی
                } else {
                    setModifyDialogOpen(false); // بستن دیالوگ اگر سفارش حذف شده باشد
                }
            }
            setPreviousPrices((prevPrices) => {
                const updatedPrices: { [key: string]: number } = {};
                data.forEach((order) => {
                    if (order.symbol) {
                        updatedPrices[order.symbol] = prevPrices[order.symbol] || order.currentPrice; // ذخیره مقادیر قبلی
                    }
                });
                return { ...prevPrices, ...updatedPrices };
            });
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
        const interval = setInterval(fetchOrders, 1000); // بروز رسانی هر 1 ثانیه
        return () => clearInterval(interval); // پاک کردن بازه زمانی
    }, []);


    const handleRowClick = (order: any) => {
        setSelectedOrder(order);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedOrder(null);
    };
    const [modifyDialogOpen, setModifyDialogOpen] = useState(false);
    const [modifyOrder, setModifyOrder] = useState<any>(null);

    const handleModify = async (order: any) => {
        try {
            const response = await fetch('http://82.115.20.86:5000/api/prices');
            const pricesData = await response.json();
            const selectedPrice = pricesData.find((item: any) => item.symbol === 'XAUUSD...');

            if (selectedPrice && selectedPrice.allowed === 0) {
                alert('Market Is Closed');
                return;
            }

            setModifyOrder(order);
            setModifyDialogOpen(true);
        } catch (error) {
            console.error('Error fetching price data:', error);
        }
    };


    const handleCloseModifyDialog = () => {
        setModifyDialogOpen(false);
        setModifyOrder(null);
    };

    // تبدیل مقدار Lot به Contract
    const convertLotToContract = (lot: string, symbol: string) => {
        const numericLot = parseFloat(lot);
        if (isNaN(numericLot)) return '0';

        switch (symbol) {
            case 'XAUUSD...':
                return (numericLot * 100).toFixed(2);
            case 'XAGUSD...':
                return (numericLot * 5000).toFixed(2);
            case 'USDCHF...':
            case 'USDJPY...':
            case 'EURNZD...':
            case 'EURJPY...':
            case 'GBPJPY...':
            case 'EURUSD...':
            case 'GBPUSD...':
                return (numericLot * 100000).toFixed(2);
            default:
                return numericLot.toFixed(2);
        }
    };

    // تبدیل مقدار Contract به Lot
    const convertContractToLot = (contract: string, symbol: string) => {
        const numericContract = parseFloat(contract);
        if (isNaN(numericContract)) return '0.00';

        switch (symbol) {
            case 'XAUUSD...':
                return (numericContract / 100).toFixed(2);
            case 'XAGUSD...':
                return (numericContract / 5000).toFixed(2);
            case 'USDCHF...':
            case 'USDJPY...':
            case 'EURNZD...':
            case 'EURJPY...':
            case 'GBPJPY...':
            case 'EURUSD...':
            case 'GBPUSD...':
                return (numericContract / 100000).toFixed(2);
            default:
                return numericContract.toFixed(2);
        }
    };
    const calculateAmountUsd = async (lot: number, symbol: string, typeOrder: string, currentPrice: number) => {
        let amountUsd = 0;
        console.log(`Calculating amountUsd for symbol: ${symbol}, lot: ${lot}, currentPrice: ${currentPrice}`);

        if (['BTCUSD', 'ETHUSD', 'US30', 'UT100', 'US500'].includes(symbol)) {
            amountUsd = lot * currentPrice;
        } else if (['USDCHF...', 'USDJPY...'].includes(symbol)) {
            amountUsd = lot * 100000;
        } else if (symbol === 'XAUUSD...') {
            amountUsd = lot * currentPrice * 100;
        } else if (symbol === 'XAGUSD...') {
            amountUsd = lot * currentPrice * 5000;
        } else if (symbol === 'EURNZD...' || symbol === 'EURJPY...') {
            const response = await axios.get('http://82.115.20.86:5000/api/prices', {
                params: { symbol: 'EURUSD...' },
            });
            const eurData = response.data.find((item: any) => item.symbol === 'EURUSD...'); // پیدا کردن اطلاعات صحیح

            if (!eurData) {
                console.error(`Error: No price data found for EURUSD..`);
                return NaN;
            }

            const eurPrice = typeOrder === 'Buy Stop' || typeOrder === 'Buy Limit' ? parseFloat(eurData.ask) : parseFloat(eurData.bid);

            if (isNaN(eurPrice)) {
                console.error(`Error: EUR Price is NaN`);
                return NaN;
            }

            amountUsd = lot * eurPrice * 100000;
        } else if (symbol === 'GBPJPY...') {
            const response = await axios.get('http://82.115.20.86:5000/api/prices', {
                params: { symbol: 'GBPUSD...' },
            });
            const gbpData = response.data.find((item: any) => item.symbol === 'GBPUSD...');

            if (!gbpData) {
                console.error(`Error: No price data found for GBPUSD...`);
                return NaN;
            }

            const gbpPrice = typeOrder === 'Buy Stop' || typeOrder === 'Buy Limit' ? parseFloat(gbpData.ask) : parseFloat(gbpData.bid);
            if (isNaN(gbpPrice)) {
                console.error(`Error: GBP Price is NaN`);
                return NaN;
            }

            amountUsd = lot * 100000 * gbpPrice;

        } else if (['EURUSD...', 'GBPUSD...'].includes(symbol)) {
            amountUsd = lot * currentPrice * 100000;
        }
        console.log(`Final amountUsd: ${amountUsd}`);
        return amountUsd;
    };


    const calculateRequiredMargin = async (amountUsd: number, userId: number) => {
        const response = await axios.get(`http://82.115.20.86:5000/api/users/${userId}/rate-margin`);
        const rateMargin = response.data.rateMargin;
        return (amountUsd * rateMargin) / 100;
    };
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [orderToClose, setOrderToClose] = useState<any>(null);
    const handleCloseOrder = async (order: any) => {
        try {
            const response = await fetch('http://82.115.20.86:5000/api/prices');
            const pricesData = await response.json();
            const selectedPrice = pricesData.find((item: any) => item.symbol === 'XAUUSD...');

            if (selectedPrice && selectedPrice.allowed === 0) {
                alert('Market Is Closed');
                return;
            }

            setOrderToClose(order);
            setConfirmDialogOpen(true);
        } catch (error) {
            console.error('Error fetching price data:', error);
        }
    };
    const handleConfirmClose = async (confirmed: boolean) => {
        if (confirmed && orderToClose) {
            try {
                const token = localStorage.getItem('token');
                await axios.put(
                    `http://82.115.20.86:5000/api/orders/close`,
                    { id: orderToClose.id },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                alert('Order closed successfully.');
                fetchOrders(); // بروز رسانی لیست سفارشات
            } catch (error) {
                console.error('Error closing order:', error);
                alert('Failed to close order.');
            }
        }
        setConfirmDialogOpen(false);
        setOrderToClose(null);
    };
    const formatPrice = (symbol: string, price: any): string => {
        // تبدیل مقدار به عدد و بررسی اینکه عدد معتبر است
        const numericPrice = parseFloat(price);
        if (isNaN(numericPrice)) {
            return '-'; // اگر مقدار معتبر نبود، مقدار پیش‌فرض برگردانید
        }

        if (['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(symbol)) {
            return numericPrice.toFixed(2);
        } else if (['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(symbol)) {
            return numericPrice.toFixed(3);
        } else if (['XAGUSD...'].includes(symbol)) {
            return numericPrice.toFixed(4);
        } else if (['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(symbol)) {
            return numericPrice.toFixed(5);
        }
        return numericPrice.toString();
    };

    const [previousPrices, setPreviousPrices] = useState<{ [key: string]: number }>({});

    useEffect(() => {

    }, [previousPrices, orders]);
    const validateInput = (value: string) => {
        const schema = Joi.string().pattern(/^\d*\.?\d*$/).message('Only numeric values and a single dot are allowed.');
        const { error } = schema.validate(value);
        return error ? error.message : null;
    };
    const validateOrder = (order: any) => {
        const schema = Joi.object({
            price: Joi.number()
                .greater(order.type === 'Buy Stop' || order.type === 'Sell Limit' ? order.currentPrice : -Infinity)
                .less(order.type === 'Buy Limit' || order.type === 'Sell Stop' ? order.currentPrice : Infinity)
                .required()
                .messages({
                    'number.greater': 'Price must be greater than Current Price.',
                    'number.less': 'Price must be less than Current Price.',
                }),
            sl: Joi.number()
                .allow(0) // اجازه مقدار 0
                .when('type', {
                    is: 'Buy Stop',
                    then: Joi.number().less(Joi.ref('price')).allow(0).required(),
                    otherwise: Joi.number().greater(Joi.ref('price')).allow(0).required(),
                })
                .messages({
                    'number.less': 'Stop Loss must be less than Price or 0.',
                    'number.greater': 'Stop Loss must be greater than Price or 0.',
                }),
            tp: Joi.number()
                .allow(0) // اجازه مقدار 0
                .when('type', {
                    is: 'Buy Stop',
                    then: Joi.number().greater(Joi.ref('price')).allow(0).required(),
                    otherwise: Joi.number().less(Joi.ref('price')).allow(0).required(),
                })
                .messages({
                    'number.greater': 'Take Profit must be greater than Price or 0.',
                    'number.less': 'Take Profit must be less than Price or 0.',
                }),
            type: Joi.string().valid('Buy Stop', 'Sell Stop', 'Buy Limit', 'Sell Limit').required(),
            currentPrice: Joi.number().required(),
        });

        const { error } = schema.validate(order, { abortEarly: false });
        if (error) {
            return error.details.map((err) => err.message).join('\n');
        }
        const maxPrice = order.currentPrice * 3;
        const minPrice = order.currentPrice / 3;

        if (order.price > maxPrice || order.price < minPrice) {
            return `Price must be between ${minPrice.toFixed(5)} and ${maxPrice.toFixed(5)}`;
        }
        if (order.sl !== 0 && (order.sl > maxPrice || order.sl < minPrice)) {
            return `Stop Loss must be between ${minPrice.toFixed(5)} and ${maxPrice.toFixed(5)} or 0.`;
        }
        if (order.tp !== 0 && (order.tp > maxPrice || order.tp < minPrice)) {
            return `Take Profit must be between ${minPrice.toFixed(5)} and ${maxPrice.toFixed(5)} or 0.`;
        }


        return null;
    };
    const [margin, setMargin] = useState<number>(0);

    // دریافت مقدار margin از localStorage در هنگام بارگذاری صفحه
    useEffect(() => {
        const storedMargin = localStorage.getItem('topBarMargin');
        if (storedMargin) {
            setMargin(parseFloat(storedMargin));
        }
    }, []);
    const [isTopBarVisible, setTopBarVisible] = useState(true);



    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            {/* Sidebar */}
            <Sidebar open={sidebarOpen} onClose={toggleSidebar} />

            {/* Main Content */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* TopBar */}
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar
                        onMenuClick={toggleSidebar}
                        onSearch={() => { }}
                        isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible} // مقدار پیش‌فرض برای تابع کنترل
                    />
                </Box>

                {/* Content Below TopBar */}
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: '64px',
                        padding: { xs: '39px', sm: '24px' },
                        overflow: 'auto',
                    }}
                >
                    {/* اگر جدول خالی است */}
                    {orders.length === 0 ? (
                        <Typography align="center" sx={{ marginTop: '50px', fontSize: '18px' }}>
                            No orders available.
                        </Typography>
                    ) : (
                        <TableContainer
                            component={Paper}
                            sx={{
                                boxShadow: 3,
                                borderRadius: 2,
                                marginTop: isMobile ? '150px' : 0, // فاصله در نسخه موبایل
                            }}
                        >
                            <Table>
                                <TableHead sx={{ backgroundColor: '#002B5B' }}>
                                    <TableRow>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Symbol</TableCell>
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Lot</TableCell>}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Type</TableCell>}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Price</TableCell>}
                                        {isMobile || (
                                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>
                                                Current Price
                                            </TableCell>
                                        )}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>SL</TableCell>}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>TP</TableCell>}
                                        {isMobile || (
                                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Order Time</TableCell>
                                        )}
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Modify</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Close</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders.map((order, index) => (
                                        <TableRow key={index} hover>
                                            <TableCell
                                                onClick={() => isMobile && handleRowClick(order)}
                                                sx={{
                                                    cursor: isMobile ? 'pointer' : 'default',
                                                    color: isMobile ? 'blue' : 'inherit',
                                                    textDecoration: isMobile ? 'underline' : 'none',
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 'bold' }}>
                                                    <img
                                                        src={`/images/${order.symbol}.png`} // مسیر آیکون سیمبول
                                                        alt={order.symbol}
                                                        onError={(e) => (e.currentTarget.style.display = 'none')} // پنهان کردن آیکون در صورت عدم وجود
                                                        style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                                                    />
                                                    {order.symbol}
                                                </Box>
                                            </TableCell>
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{order.lot}</TableCell>}
                                            {isMobile || (
                                                <TableCell
                                                    sx={{
                                                        color: ['Buy Stop', 'Buy Limit'].includes(order.type) ? 'green' : ['Sell Stop', 'Sell Limit'].includes(order.type) ? 'red' : 'inherit',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {order.type}
                                                </TableCell>
                                            )}
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{order.price ? formatPrice(order.symbol, order.price) : '-'}</TableCell>}
                                            {isMobile || (
                                                <TableCell
                                                    sx={{
                                                        color:
                                                            order.currentPrice > (previousPrices[order.symbol] || 0)
                                                                ? 'green'
                                                                : order.currentPrice < (previousPrices[order.symbol] || 0)
                                                                    ? 'red'
                                                                    : 'inherit',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {order.currentPrice ? formatPrice(order.symbol, order.currentPrice) : '-'}
                                                </TableCell>

                                            )}
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{order.sl}</TableCell>}
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{order.tp}</TableCell>}
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{order.orderTime}</TableCell>}
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => handleModify(order)}
                                                    disabled={!order}
                                                >
                                                    Modify
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => handleCloseOrder(order)}
                                                >
                                                    Close
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Box>

            {/* Dialog for Mobile View */}
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
                <DialogTitle sx={{ backgroundColor: '#002B5B', color: '#fff' }}>Order Details</DialogTitle>
                <DialogContent>
                    {selectedOrder && (
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="body1"><strong>Symbol:</strong></Typography>
                                <img
                                    src={`/images/${selectedOrder.symbol}.png`} // مسیر آیکون سیمبول
                                    alt={selectedOrder.symbol}
                                    onError={(e) => (e.currentTarget.style.display = 'none')} // پنهان کردن آیکون در صورت عدم وجود
                                    style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                                />
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{selectedOrder.symbol}</Typography>
                            </Box>

                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}><strong>Lot:</strong> {selectedOrder.lot}</Typography>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}><strong>Type:</strong> {selectedOrder.type}</Typography>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}><strong>Price:</strong> {selectedOrder.price}</Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color:
                                        previousPrices[selectedOrder.symbol] && previousPrices[selectedOrder.symbol] < selectedOrder.currentPrice
                                            ? 'green'
                                            : previousPrices[selectedOrder.symbol] > selectedOrder.currentPrice
                                                ? 'red'
                                                : 'inherit',
                                    fontWeight: 'bold',
                                }}
                            >
                                <strong>Current Price:</strong> {formatPrice(selectedOrder.symbol, selectedOrder.currentPrice)}
                            </Typography>

                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}><strong>SL:</strong> {selectedOrder.sl}</Typography>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}><strong>TP:</strong> {selectedOrder.tp}</Typography>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                <strong>Order Time:</strong> {selectedOrder.orderTime}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>

            <Dialog
                open={modifyDialogOpen}
                onClose={handleCloseModifyDialog}
                fullWidth
                PaperProps={{
                    sx: {
                        width: isMobile ? '100%' : '50%',
                        maxWidth: '600px',
                        height: 'auto',
                        borderRadius: '16px',
                        padding: '16px',
                        backgroundColor: '#f7f9fc',
                        boxShadow: 24,
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: '#002B5B',
                        color: '#fff',
                        padding: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 2,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <img
                            src={`/images/${modifyOrder?.symbol}.png`}
                            alt={modifyOrder?.symbol}
                            onError={(e) => (e.currentTarget.style.display = 'none')}
                            style={{ width: '32px', height: '32px', borderRadius: '50%' }}
                        />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {modifyOrder?.symbol}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            gap: 1,
                        }}
                    >
                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#FFD700' }}>
                            {modifyOrder?.type || '-'}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color:
                                    modifyOrder &&
                                        previousPrices[modifyOrder.symbol] &&
                                        previousPrices[modifyOrder.symbol] < modifyOrder.currentPrice
                                        ? 'green'
                                        : modifyOrder &&
                                            previousPrices[modifyOrder.symbol] > modifyOrder.currentPrice
                                            ? 'red'
                                            : 'inherit',
                                fontWeight: 'bold',
                            }}
                        >
                            {modifyOrder && formatPrice(modifyOrder.symbol, modifyOrder.currentPrice)}
                        </Typography>
                    </Box>
                    <Button onClick={handleCloseModifyDialog} style={{ color: '#fff' }}>✕</Button>
                </DialogTitle>
                <DialogContent
                    sx={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    {modifyOrder && (
                        <>
                            <Box>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                    Lot:
                                </Typography>
                                <Typography>{modifyOrder.lot}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Contract:</Typography>
                                <input
                                    type="text"
                                    defaultValue={convertLotToContract(modifyOrder.lot, modifyOrder.symbol)}
                                    onChange={(e) => {
                                        const errorMessage = validateInput(e.target.value);
                                        if (!errorMessage) {
                                            const newLot = convertContractToLot(e.target.value, modifyOrder.symbol);
                                            setModifyOrder((prev: any) => ({ ...prev, lot: newLot }));
                                        } else {
                                            alert(errorMessage);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        padding: '12px',
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Price:</Typography>
                                <input
                                    type="text"
                                    defaultValue={modifyOrder.price}
                                    onChange={(e) => {
                                        const errorMessage = validateInput(e.target.value);
                                        if (!errorMessage) {
                                            setModifyOrder((prev: any) => ({ ...prev, price: e.target.value }));
                                        } else {
                                            alert(errorMessage);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        padding: '12px',
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Stop Loss:</Typography>
                                <input
                                    type="text"
                                    defaultValue={modifyOrder.sl}
                                    onChange={(e) => {
                                        const errorMessage = validateInput(e.target.value);
                                        if (!errorMessage) {
                                            setModifyOrder((prev: any) => ({ ...prev, sl: e.target.value }));
                                        } else {
                                            alert(errorMessage);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        padding: '12px',
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                    }}
                                />

                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Take Profit:</Typography>
                                <input
                                    type="text"
                                    defaultValue={modifyOrder.tp}
                                    onChange={(e) => {
                                        const errorMessage = validateInput(e.target.value);
                                        if (!errorMessage) {
                                            setModifyOrder((prev: any) => ({ ...prev, tp: e.target.value }));
                                        } else {
                                            alert(errorMessage);
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        padding: '12px',
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '16px',
                    }}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCloseModifyDialog}
                        sx={{ padding: '12px 24px', fontWeight: 'bold' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            const validationError = validateOrder({
                                price: parseFloat(modifyOrder.price),
                                sl: parseFloat(modifyOrder.sl),
                                tp: parseFloat(modifyOrder.tp),
                                type: modifyOrder.type,
                                currentPrice: parseFloat(modifyOrder.currentPrice),
                            });

                            if (validationError) {
                                alert(validationError);
                                return;
                            }

                            try {
                                const amountUsd = await calculateAmountUsd(
                                    parseFloat(modifyOrder.lot),
                                    modifyOrder.symbol,
                                    modifyOrder.type,
                                    parseFloat(modifyOrder.currentPrice)
                                );
                                const requiredMargin = await calculateRequiredMargin(amountUsd, getUserId());
                                if (requiredMargin > margin) {
                                    alert('The required margin exceeds your available margin. Please adjust the Contract value.');
                                    return;
                                }
                                const payload = {
                                    lot: modifyOrder.lot,
                                    price: modifyOrder.price,
                                    sl: modifyOrder.sl,
                                    tp: modifyOrder.tp,
                                    requiredmargin: requiredMargin.toFixed(2),
                                    id: modifyOrder.id,
                                };
                                await axios.put('http://82.115.20.86:5000/api/orders', payload);
                                alert('Order updated successfully!');
                                setModifyDialogOpen(false);
                                fetchOrders();
                            } catch (error) {
                                console.error('Error updating order:', error);
                                alert('Failed to update order.');
                            }
                        }}
                        sx={{ padding: '12px 24px', fontWeight: 'bold' }}
                    >
                        Save
                    </Button>

                </DialogActions>
            </Dialog>


            <Dialog
                open={confirmDialogOpen}
                onClose={() => handleConfirmClose(false)}
            >
                <DialogTitle>Close Order</DialogTitle>
                <DialogContent>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        Are you sure you want to close this order?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmClose(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleConfirmClose(true)} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );

};

export default OrdersPage;

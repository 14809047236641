import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
interface StrategyTableProps {
    refresh: boolean;
    filterMode: 'current' | 'old' | null; // prop جدید برای فیلتر
}


const StrategyTable: React.FC<StrategyTableProps> = ({ refresh, filterMode }) => {

    const [strategies, setStrategies] = useState<any[]>([]);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStrategy, setSelectedStrategy] = useState<{
        username: string;
        strategyName: string;
        instrumentSymbol: string;
        tradeStyle: string;
        timeframeAnalysis: string;
        marketConditions: string;
        riskRewardRatio: string;
        capitalAllocated: string;
        comment: string;
    } | null>(null);

    const getUserId = (): number | null => {
        const token = localStorage.getItem('token');
        if (!token) return null;

        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.id;
        } catch (error) {
            console.error('Invalid token:', error);
            return null;
        }
    };

    const fetchStrategies = () => {
        const userId = getUserId();
        if (!userId) {
            alert('User not logged in');
            return;
        }

        const filteredStrategies = (strategies: any[]) => {
            if (filterMode === 'current') {
                return strategies.filter((s) => s.strategy_state !== 'used');
            } else if (filterMode === 'old') {
                return strategies.filter((s) => s.strategy_state === 'used');
            }
            return strategies;
        };

        const url = `http://82.115.20.86:5000/api/strategies?userId=${userId}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log('Fetched Strategies:', data);
                setStrategies(filteredStrategies(data));
            })
            .catch((error) => {
                console.error('Error fetching strategies:', error);
            });
    };



    useEffect(() => {
        console.log('Fetching strategies with filterMode:', filterMode);
        fetchStrategies();
    }, [refresh, filterMode]);


    const handleViewClick = (strategy: any) => {
        setSelectedStrategy({
            username: 'Logged-in User',
            strategyName: strategy.strategy_name,
            instrumentSymbol: strategy.instrument_symbol,
            tradeStyle: strategy.trade_style,
            timeframeAnalysis: strategy.timeframe_analysis,
            marketConditions: strategy.market_conditions,
            riskRewardRatio: strategy.risk_reward_ratio,
            capitalAllocated: strategy.capital_allocated,
            comment: strategy.comments,
        });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedStrategy(null);
    };
    const [modifyDialogOpen, setModifyDialogOpen] = useState(false);
    const [modifyStrategyData, setModifyStrategyData] = useState({
        id: 0,
        strategyName: '',
        instrumentSymbol: '',
        tradeStyle: '',
        timeframeAnalysis: [] as string[],
        marketConditions: '',
        riskRewardRatio: '',
        capitalAllocated: '',
        comment: '',
    });
    const handleModifyClick = (strategy: any) => {
        setModifyStrategyData({
            id: strategy.id, // اضافه کردن id
            strategyName: strategy.strategy_name,
            instrumentSymbol: strategy.instrument_symbol,
            tradeStyle: strategy.trade_style,
            timeframeAnalysis: strategy.timeframe_analysis.split(','), // تبدیل به آرایه
            marketConditions: strategy.market_conditions,
            riskRewardRatio: strategy.risk_reward_ratio,
            capitalAllocated: strategy.capital_allocated,
            comment: strategy.comments,
        });
        setModifyDialogOpen(true);
    };
    const handleCloseModifyDialog = () => {
        setModifyDialogOpen(false);
    };
    const handleModifyInputChange = (field: string, value: any) => {
        setModifyStrategyData((prev) => ({ ...prev, [field]: value }));
    };
    const handleSubmitModify = async () => {
        try {
            const response = await fetch('http://82.115.20.86:5000/api/strategies/update', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: modifyStrategyData.id, // ارسال id به سرور
                    strategyName: modifyStrategyData.strategyName,
                    instrumentSymbol: modifyStrategyData.instrumentSymbol,
                    tradeStyle: modifyStrategyData.tradeStyle,
                    timeframeAnalysis: modifyStrategyData.timeframeAnalysis.join(','), // تبدیل به رشته
                    marketConditions: modifyStrategyData.marketConditions,
                    riskRewardRatio: modifyStrategyData.riskRewardRatio,
                    capitalAllocated: modifyStrategyData.capitalAllocated,
                    comment: modifyStrategyData.comment,
                }),
            });

            if (response.ok) {
                alert('Strategy updated successfully!');
                setModifyDialogOpen(false);
                // بازخوانی داده‌ها
                const userId = getUserId();
                fetch(`http://82.115.20.86:5000/api/strategies?userId=${userId}`)
                    .then((res) => res.json())
                    .then((data) => setStrategies(data));
            } else {
                alert('Failed to update strategy.');
            }
        } catch (error) {
            console.error('Error updating strategy:', error);
            alert('An error occurred. Please try again.');
        }
    };
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedStrategyId, setSelectedStrategyId] = useState<number | null>(null);
    const handleDeleteClick = (id: number) => {
        setSelectedStrategyId(id);
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (!selectedStrategyId) return;

        try {
            const response = await fetch(`http://82.115.20.86:5000/api/strategies/${selectedStrategyId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                alert('Strategy deleted successfully!');
                setDeleteDialogOpen(false);
                setSelectedStrategyId(null);
                // بازخوانی داده‌ها
                const userId = getUserId();
                fetch(`http://82.115.20.86:5000/api/strategies?userId=${userId}`)
                    .then((res) => res.json())
                    .then((data) => setStrategies(data));
            } else {
                alert('Failed to delete strategy.');
            }
        } catch (error) {
            console.error('Error deleting strategy:', error);
            alert('An error occurred. Please try again.');
        }
    };




    return (
        <Box
            sx={{
                flexGrow: 1,
                overflow: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                height: '100%',
            }}
        >
            <TableContainer component={Paper} sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    width: '10%',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                Strategy Id
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: '20%',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                Strategy Name
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: '40%',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {strategies.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center" sx={{ fontWeight: 'bold' }} >
                                    No Data Available
                                </TableCell>
                            </TableRow>
                        ) : (
                            strategies.map((strategy) => (
                                <TableRow key={strategy.strategy_id}>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{strategy.strategy_id}</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{strategy.strategy_name}</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            sx={{ marginRight: 1, textTransform: 'none' }}
                                            onClick={() => handleViewClick(strategy)}
                                        >
                                            View
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            sx={{ marginRight: 1, textTransform: 'none' }}
                                            onClick={() => handleModifyClick(strategy)}
                                            disabled={strategy.strategy_state === 'used'}
                                        >
                                            Modify
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            sx={{ textTransform: 'none' }}
                                            onClick={() => handleDeleteClick(strategy.id)}
                                            disabled={strategy.strategy_state === 'used'}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
                    Strategy Details
                </DialogTitle>
                <DialogContent>
                    {selectedStrategy && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                marginTop: 2,
                                padding: 2,
                                backgroundColor: '#f9f9f9',
                                borderRadius: '8px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                            }}
                        >
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Username:</strong> {selectedStrategy.username}
                            </Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Strategy Name:</strong> {selectedStrategy.strategyName}
                            </Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Instrument Symbol:</strong> {selectedStrategy.instrumentSymbol}
                            </Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Trade Style:</strong> {selectedStrategy.tradeStyle}
                            </Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Timeframe Analysis:</strong> {selectedStrategy.timeframeAnalysis}
                            </Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Market Conditions:</strong> {selectedStrategy.marketConditions}
                            </Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Risk Reward Ratio:</strong> {selectedStrategy.riskRewardRatio}
                            </Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Capital Allocated:</strong> {selectedStrategy.capitalAllocated}
                            </Box>
                            <Box sx={{ fontWeight: 'bold' }}>
                                <strong>Comment:</strong> {selectedStrategy.comment}
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        sx={{
                            margin: '0 auto',
                            padding: '10px 20px',
                            textTransform: 'none',
                            backgroundColor: '#4CAF50',
                            color: '#fff',
                            borderRadius: '8px',
                            fontSize: '1rem',
                            '&:hover': {
                                backgroundColor: '#45a049',
                            },
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={modifyDialogOpen} onClose={handleCloseModifyDialog} maxWidth="md" fullWidth>
                <DialogTitle>Modify Strategy</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginTop: 2 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '4px' }}>
                            ID
                        </Typography>
                        <TextField

                            fullWidth
                            value={modifyStrategyData.id}
                            disabled
                            InputProps={{ readOnly: true }} // خواندنی
                            sx={{ marginBottom: 2, backgroundColor: '#f9f9f9' }} // فاصله با بقیه فیلدها
                        />
                        <TextField
                            label="Strategy Name"
                            fullWidth
                            value={modifyStrategyData.strategyName}
                            onChange={(e) => handleModifyInputChange('strategyName', e.target.value)}
                        />
                        <FormControl fullWidth>
                            <InputLabel>Instrument Symbol</InputLabel>
                            <Select
                                value={modifyStrategyData.instrumentSymbol}
                                onChange={(e) => handleModifyInputChange('instrumentSymbol', e.target.value)}
                            >
                                {['BTCUSD', 'ETHUSD', 'EURUSD...', 'GBPUSD...', 'XAUUSD...', 'XAGUSD...', 'USDCHF...', 'USDJPY...', 'EURNZD...', 'EURJPY...', 'GBPJPY...', 'US30', 'UT100', 'US500'].map((symbol) => (
                                    <MenuItem key={symbol} value={symbol}>
                                        {symbol}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Trade Style</InputLabel>
                            <Select
                                value={modifyStrategyData.tradeStyle}
                                onChange={(e) => handleModifyInputChange('tradeStyle', e.target.value)}
                            >
                                {['Scalping', 'Day Trading', 'Swing Trading', 'Position Trading'].map((style) => (
                                    <MenuItem key={style} value={style}>
                                        {style}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Risk/Reward Ratio"
                            fullWidth
                            value={modifyStrategyData.riskRewardRatio}
                            onChange={(e) => handleModifyInputChange('riskRewardRatio', e.target.value)}
                        />
                        <TextField
                            label="Amount of Capital Allocated"
                            fullWidth
                            value={modifyStrategyData.capitalAllocated}
                            onChange={(e) => handleModifyInputChange('capitalAllocated', e.target.value)}
                        />
                        <FormControl fullWidth>
                            <InputLabel>Market Conditions</InputLabel>
                            <Select
                                value={modifyStrategyData.marketConditions}
                                onChange={(e) => handleModifyInputChange('marketConditions', e.target.value)}
                            >
                                {['Bullish', 'Bearish', 'Ranging', 'Volatile', 'Stable'].map((condition) => (
                                    <MenuItem key={condition} value={condition}>
                                        {condition}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                            Timeframe Of Analysis
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                            {['1min', '5min', '15min', '30min', '1hour', '2hour', '4hour', 'Daily', 'Weekly'].map((time) => (
                                <Button
                                    key={time}
                                    variant={modifyStrategyData.timeframeAnalysis.includes(time) ? 'contained' : 'outlined'}
                                    onClick={() =>
                                        handleModifyInputChange(
                                            'timeframeAnalysis',
                                            modifyStrategyData.timeframeAnalysis.includes(time)
                                                ? modifyStrategyData.timeframeAnalysis.filter((t) => t !== time)
                                                : [...modifyStrategyData.timeframeAnalysis, time]
                                        )
                                    }
                                    sx={{ textTransform: 'none' }}
                                >
                                    {time}
                                </Button>
                            ))}
                        </Box>
                        <TextField
                            label="Comments"
                            multiline
                            rows={4}
                            fullWidth
                            value={modifyStrategyData.comment}
                            onChange={(e) => handleModifyInputChange('comment', e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModifyDialog} color="secondary" variant="outlined">
                        Close
                    </Button>
                    <Button color="primary" variant="contained" onClick={handleSubmitModify}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        Do you really want to delete this strategy? This process cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
};

export default StrategyTable;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TradeModal from './TradeModal';

interface PriceTableProps {
  searchTerm: string;
}


const PriceTable: React.FC<PriceTableProps> = ({ searchTerm }) => {
  const [previousPrices, setPreviousPrices] = useState<Record<string, { bid: number; ask: number }>>({});
  const [prices, setPrices] = useState<{ id: string; bid: number; ask: number;[key: string]: any }[]>([]);
  const [filteredPrices, setFilteredPrices] = useState<{ id: string; bid: number; ask: number;[key: string]: any }[]>([]);

  const [symbolModalData, setSymbolModalData] = useState<any>(null); // اطلاعات برای پنجره Symbol
  const [modalData, setModalData] = useState<any>(null); // اطلاعات برای پنجره خرید/فروش
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // شناسایی نسخه موبایل

  const fetchPrices = async () => {
    try {
      const response = await axios.get('http://82.115.20.86:5000/api/prices');
      let data = response.data.slice(0, 14);

      if (allowedSymbols.length > 0) {
        data = data.filter((price: any) => allowedSymbols.includes(price.symbol));
      }
      setPrices((prevPrices) => {
        const prevPricesMap: Record<string, { bid: number; ask: number }> = {};
        prevPrices.forEach((price: { id: string; bid: number; ask: number }) => {
          prevPricesMap[price.id] = { bid: price.bid, ask: price.ask };
        });
        setPreviousPrices(prevPricesMap);
        return data;
      });

      setFilteredPrices(data);
    } catch (error) {
      console.error('Error fetching price data:', error);
    }
  };


  const [allowedSymbols, setAllowedSymbols] = useState<string[]>([]);

  const fetchAllowedSymbols = async () => {
    try {
      const token = localStorage.getItem('token'); // استفاده از توکن برای دریافت اطلاعات کاربر
      if (!token) return;

      // درخواست اطلاعات کاربر
      const userInfoResponse = await axios.get('http://82.115.20.86:5000/api/users/info', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const userId = userInfoResponse.data?.id;
      if (!userId) return;

      // دریافت سمبل‌های مجاز بر اساس userId
      const response = await axios.get(`http://82.115.20.86:5000/api/user-symbols?userId=${userId}`);
      setAllowedSymbols(response.data);
    } catch (error) {
      console.error('Error fetching allowed symbols:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllowedSymbols(); // ابتدا allowedSymbols را مقداردهی کن
    };

    fetchData();
  }, []);

  // زمانی که allowedSymbols مقداردهی شد، fetchPrices را اجرا کن
  useEffect(() => {
    if (allowedSymbols.length > 0) {
      fetchPrices();
      const interval = setInterval(fetchPrices, 1000);
      return () => clearInterval(interval);
    }
  }, [allowedSymbols]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredPrices(prices);
    } else {
      const filtered = prices.filter((price: any) =>
        Object.values(price).some((value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredPrices(filtered);
    }
  }, [searchTerm, prices]);

  const handleOpenSymbolModal = (price: any) => {
    if (isMobile) {
      setSymbolModalData(price); // فقط در موبایل فعال
    }
  };

  const handleCloseSymbolModal = () => {
    setSymbolModalData(null);
  };

  const handleOpenModal = (type: 'Buy' | 'Sell', price: any) => {
    setModalData({ type, ...price });
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  const formatValue = (value: string, symbol: string) => {
    const parsedValue = parseFloat(value);
    if (['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(symbol)) {
      return parsedValue.toFixed(2);
    }
    if (['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(symbol)) {
      return parsedValue.toFixed(3);
    }
    if (['XAGUSD...'].includes(symbol)) {
      return parsedValue.toFixed(4);
    }
    if (['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(symbol)) {
      return parsedValue.toFixed(5);
    }
    return value;
  };
  const [loading, setLoading] = useState(true); // اضافه کردن state برای لودینگ

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // شروع لودینگ
      await fetchPrices(); // فراخوانی تابع fetchPrices
      setLoading(false); // پایان لودینگ
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: isMobile ? 'auto' : 'calc(100vh - 50px)', // فقط در دسکتاپ ارتفاع افزایش پیدا کند
            overflow: 'unset',
          }}
        >
          <Table aria-label="price table">
            <TableHead
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white', // رنگ پس‌زمینه برای جلوگیری از تداخل با اسکرول
                zIndex: 1000, // اطمینان از اینکه هدر بالای جدول می‌ماند
              }}
            >
              <TableRow>
                {isMobile ? (
                  <>
                    <TableCell sx={{ fontWeight: 'bold' }}>Symbol</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Sell Button</TableCell>
                    <TableCell>Buy Button</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell sx={{ fontWeight: 'bold' }}>Active</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Symbol</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Change</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Sell</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Sell Button</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Buy</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Buy Button</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>High</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Low</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPrices.map((price: any) => (
                <TableRow key={price.id}>
                  {isMobile ? (
                    <>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <img
                            src={`/images/${price.symbol}.png`}
                            alt={price.symbol}
                            style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                          />
                          <Button
                            onClick={() => {
                              if (isMobile) {
                                handleOpenSymbolModal(price); // باز کردن پنجره برای نسخه موبایل
                              }
                              const event = new CustomEvent('symbolChange', { detail: price.symbol }); // ایجاد رویداد سفارشی
                              window.dispatchEvent(event); // ارسال رویداد برای تغییر چارت
                            }}
                            sx={{ textTransform: 'none' }}
                          >
                            {price.symbol}
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            handleOpenModal('Sell', price);
                            const event = new CustomEvent('symbolChange', { detail: price.symbol });
                            window.dispatchEvent(event);
                          }}
                          disabled={!allowedSymbols.some((symbol) => symbol === price.symbol)}
                          sx={{
                            minWidth: '70px', // عرض کوچکتر در نسخه موبایل
                            height: '50px', // ارتفاع کمتر برای دکمه
                            fontSize: '12px', // متن کوچکتر در نسخه موبایل
                            padding: '4px 8px', // تنظیم فاصله داخلی
                            fontWeight: 'bold',
                          }}
                        >
                          Sell: {formatValue(price.bid.toString(), price.symbol)}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            handleOpenModal('Buy', price);
                            const event = new CustomEvent('symbolChange', { detail: price.symbol }); // ایجاد رویداد برای تغییر چارت
                            window.dispatchEvent(event); // ارسال رویداد
                          }}
                          disabled={!allowedSymbols.some((symbol) => symbol === price.symbol)}
                          sx={{
                            minWidth: '70px', // عرض کوچکتر در نسخه موبایل
                            height: '50px', // ارتفاع کمتر برای دکمه
                            fontSize: '12px', // متن کوچکتر در نسخه موبایل
                            padding: '4px 8px', // تنظیم فاصله داخلی
                            fontWeight: 'bold',
                          }}
                        >
                          Buy: {formatValue(price.ask.toString(), price.symbol)}
                        </Button>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        {allowedSymbols.some((symbol) => symbol === price.symbol) ? '✅' : '❌'}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <img
                            src={`/images/${price.symbol}.png`}
                            alt={price.symbol}
                            style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                          />
                          <Button
                            onClick={() => {
                              const event = new CustomEvent('symbolChange', { detail: price.symbol }); // ایجاد رویداد برای تغییر چارت
                              window.dispatchEvent(event); // ارسال رویداد
                            }}
                            sx={{ textTransform: 'none' }}
                          >
                            {price.symbol}
                          </Button>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{
                          color: parseFloat(price.change_percent) > 0 ? 'green' : 'red',
                          fontWeight: 'bold',
                        }}
                      >
                        {parseFloat(price.change_percent) > 0
                          ? `+${parseFloat(price.change_percent).toFixed(2)}`
                          : parseFloat(price.change_percent).toFixed(2)}%
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            previousPrices[price.id]?.bid !== undefined &&
                              price.bid > previousPrices[price.id].bid
                              ? 'green'
                              : previousPrices[price.id]?.bid !== undefined &&
                                price.bid < previousPrices[price.id].bid
                                ? 'red'
                                : 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {formatValue(price.bid.toString(), price.symbol)}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            handleOpenModal('Sell', price);
                            const event = new CustomEvent('symbolChange', { detail: price.symbol }); // ایجاد رویداد برای تغییر چارت
                            window.dispatchEvent(event); // ارسال رویداد
                          }}
                          disabled={!allowedSymbols.some((symbol) => symbol === price.symbol)}
                        >
                          Sell
                        </Button>
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            previousPrices[price.id]?.ask !== undefined &&
                              price.ask > previousPrices[price.id].ask
                              ? 'green'
                              : previousPrices[price.id]?.ask !== undefined &&
                                price.ask < previousPrices[price.id].ask
                                ? 'red'
                                : 'black',
                          fontWeight: 'bold',
                        }}
                      >
                        {formatValue(price.ask.toString(), price.symbol)}
                      </TableCell>

                      <TableCell>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            handleOpenModal('Buy', price);
                            const event = new CustomEvent('symbolChange', { detail: price.symbol }); // ایجاد رویداد برای تغییر چارت
                            window.dispatchEvent(event); // ارسال رویداد
                          }}
                          disabled={!allowedSymbols.some((symbol) => symbol === price.symbol)}
                        >
                          Buy
                        </Button>
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>{formatValue(price.high, price.symbol)}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>{formatValue(price.low, price.symbol)}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* پنجره اطلاعات Symbol */}
      {symbolModalData && (
        <Dialog open={!!symbolModalData} onClose={handleCloseSymbolModal}>
          <DialogTitle>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{symbolModalData.symbol}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontWeight: 'bold' }}>Active: {symbolModalData.allowed ? '✅' : '❌'}</Typography>
            <Typography
              sx={{
                color: parseFloat(symbolModalData.change_percent) > 0 ? 'green' : 'red',
                fontWeight: 'bold',
              }}
            >
              Change: {parseFloat(symbolModalData.change_percent) > 0
                ? `+${parseFloat(symbolModalData.change_percent).toFixed(2)}`
                : parseFloat(symbolModalData.change_percent).toFixed(2)}%
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }}>Sell: {formatValue(symbolModalData.bid, symbolModalData.symbol)}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>Buy: {formatValue(symbolModalData.ask, symbolModalData.symbol)}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>High: {formatValue(symbolModalData.high, symbolModalData.symbol)}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>Low: {formatValue(symbolModalData.low, symbolModalData.symbol)}</Typography>
          </DialogContent>
          <Button onClick={handleCloseSymbolModal} sx={{ margin: 2 }} variant="contained">
            Close
          </Button>
        </Dialog>
      )}

      {/* پنجره خرید/فروش */}
      {modalData && (
        <TradeModal
          open={!!modalData}
          onClose={handleCloseModal}
          symbol={modalData.symbol}
          change={modalData.change_percent}
          value={modalData.type === 'Buy' ? modalData.ask : modalData.bid}
          type={modalData.type}
        />
      )}
    </>
  );
};

export default PriceTable;

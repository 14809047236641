import React, { useEffect, useState, useRef } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, Button, useMediaQuery, useTheme, Typography } from '@mui/material';
import axios from 'axios';

const ClosePositions: React.FC = () => {

    const [positions, setPositions] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const tableRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);


    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getUserId = () => {
        const token = localStorage.getItem('token');
        if (!token) return null;

        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.id;
        } catch {
            return null;
        }
    };
    const [searchTerm, setSearchTerm] = useState(''); // برای نگهداری مقدار جستجو

    const filteredPositions = positions.filter(position =>
        position.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        position.open_time.toString().includes(searchTerm) ||
        position.close_time.toString().includes(searchTerm)
    );

    const fetchPositions = async () => {
        try {
            // ذخیره موقعیت اسکرول
            const scrollTop = scrollRef.current?.scrollTop || 0;

            setLoading(true);
            const userId = getUserId();
            if (!userId) {
                alert('User ID not found. Please login again.');
                return;
            }

            const response = await axios.get('http://82.115.20.86:5000/api/close-positions', {
                params: { userId },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            setPositions((prevPositions) => {
                if (JSON.stringify(prevPositions) !== JSON.stringify(response.data)) {
                    return response.data.map((position: any, index: number) => ({
                        ...prevPositions[index],
                        ...position,
                    }));
                }
                return prevPositions;
            });


            // بازگرداندن موقعیت اسکرول
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollTop;
            }
        } catch (error) {
            console.error('Error fetching positions:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const userId = getUserId();
        if (!userId) {
            window.location.href = '/'; // انتقال به صفحه اصلی اگر کاربر سشن ندارد
        } else {
            fetchPositions();
        }

        // تنظیم تایمر برای رفرش هر ۵ ثانیه
        const interval = setInterval(() => {
            fetchPositions();
        }, 50000000);

        return () => clearInterval(interval); // پاک کردن تایمر هنگام خروج از صفحه
    }, []);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState<any>(null);

    const handleRowClick = (position: any) => {
        setSelectedPosition(position);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedPosition(null);
    };
    const formatValueBySymbol = (value: number, symbol: string) => {
        if (['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(symbol)) {
            return value.toFixed(2);
        }
        if (['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(symbol)) {
            return value.toFixed(3);
        }
        if (['XAGUSD...'].includes(symbol)) {
            return value.toFixed(4);
        }
        if (['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(symbol)) {
            return value.toFixed(5);
        }
        return value; // پیش‌فرض بدون فرمت
    };
    const [isTopBarVisible, setTopBarVisible] = useState(true);

    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <Sidebar open={sidebarOpen} onClose={toggleSidebar} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar
                        onMenuClick={toggleSidebar}
                        onSearch={(value) => setSearchTerm(value)}
                        isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible}
                    />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: { xs: '200px', sm: '64px' },
                        padding: { xs: '16px', sm: '24px' },
                        overflow: 'auto',
                    }}
                >
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '16px',
                            marginBottom: '16px',
                            backgroundColor: '#002B5B',
                            color: '#fff',
                            borderRadius: 2,
                            boxShadow: 3,
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Total PNL:
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                color: positions.reduce((total, position) => total + parseFloat(position.profit || 0), 0) >= 0 ? 'green' : 'red',
                            }}
                        >
                            {positions.reduce((total, position) => total + parseFloat(position.profit || 0), 0).toFixed(2)}
                        </Typography>
                    </Paper>
                    {loading ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>Loading...</Typography>
                    ) : positions.length === 0 ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>No positions found.</Typography>
                    ) : (
                        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#002B5B' }}>
                                    <TableRow>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Symbol</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Type</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Profit</TableCell>
                                        {!isMobile && (
                                            <>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Strategy Name</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Lot</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Open Price</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Close Price</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>SL</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>TP</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Open Time</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Close Time</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredPositions.map((position, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                onClick={() => isMobile && handleRowClick(position)}
                                                sx={{
                                                    cursor: isMobile ? 'pointer' : 'default',
                                                    textDecoration: isMobile ? 'underline' : 'none',
                                                    color: isMobile ? 'blue' : 'inherit',
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 'bold' }}>
                                                    <img
                                                        src={`/images/${position.symbol}.png`}
                                                        alt={position.symbol}
                                                        style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                                                    />
                                                    {position.symbol}
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color: position.type === 'SELL' ? 'red' : 'green',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {position.type === 'SELL' ? 'Sell' : 'Buy'}
                                            </TableCell>

                                            <TableCell
                                                sx={{
                                                    color: parseFloat(position.profit) < 0 ? 'red' : 'green',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {parseFloat(position.profit).toFixed(2)}
                                            </TableCell>
                                            {!isMobile && (
                                                <>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{position.strategy_name}</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(position.lot).toFixed(2)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{formatValueBySymbol(parseFloat(position.open_price), position.symbol)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{formatValueBySymbol(parseFloat(position.close_price), position.symbol)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{formatValueBySymbol(parseFloat(position.sl), position.symbol)}</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{formatValueBySymbol(parseFloat(position.tp), position.symbol)}</TableCell>

                                                    <TableCell sx={{ fontWeight: 'bold' }}>{position.open_time}</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{position.close_time}</TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                    )}
                </Box>
            </Box>
            {isMobile && (
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    fullWidth
                    maxWidth="sm"
                    PaperProps={{
                        sx: {
                            padding: 3,
                            borderRadius: 2,
                            boxShadow: 10,
                        },
                    }}
                >
                    <DialogTitle sx={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center' }}>
                        Position Details
                    </DialogTitle>
                    <DialogContent>
                        {selectedPosition && (
                            <Box>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Strategy Name:</strong> {selectedPosition.strategy_name}</Typography>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Lot:</strong> {parseFloat(selectedPosition.lot).toFixed(2)}</Typography>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Open Price:</strong> {selectedPosition.open_price}</Typography>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Close Price:</strong> {selectedPosition.close_price}</Typography>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>SL:</strong> {selectedPosition.sl}</Typography>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>TP:</strong> {selectedPosition.tp}</Typography>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Open Time:</strong> {selectedPosition.open_time}</Typography>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Close Time:</strong> {selectedPosition.close_time}</Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDialogClose}
                                sx={{ padding: '10px 20px' }}
                            >
                                Close
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}


        </Box>
    );
};

export default ClosePositions;

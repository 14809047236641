import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import Crud from './pages/Crud';
import OrdersAdmin from './pages/OrdersAdmin';
import OpenPositionsAdmin from './pages/OpenPositionsAdmin';
import ClosePositionsAdmin from './pages/closepositionsadmin';
import StrategiesAdmin from './pages/StrategiesAdmin';
import FundsAdmin from './pages/FundsAdmin';
import OpenPositionsPage from './pages/open-positions';
import ClosePositions from './pages/close-positions';
import StrategiesPage from './pages/strategies';
import OrdersPage from './pages/OrdersPage';
import Funds from './pages/Funds';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/crud" element={<Crud />} /> {/* اضافه کردن مسیر جدید */}
        <Route path="/ordersadmin" element={<OrdersAdmin />} />
        <Route path="/openpositionsadmin" element={<OpenPositionsAdmin />} />
        <Route path="/closepositionsadmin" element={<ClosePositionsAdmin />} />
        <Route path="/StrategiesAdmin" element={<StrategiesAdmin />} />
        <Route path="/FundsAdmin" element={<FundsAdmin />} />
        <Route path="/open-positions" element={<OpenPositionsPage />} />
        <Route path="/close-positions" element={<ClosePositions />} />
        <Route path="/OrdersPage" element={<OrdersPage />} />
        <Route path="/strategies" element={<StrategiesPage />} />
        <Route path="/Funds" element={<Funds />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import { Box, Typography, useMediaQuery, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

const Funds: React.FC = () => {

    const [fundsData, setFundsData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getUserId = (): string | null => {
        const token = localStorage.getItem('token');
        if (!token) return null;
        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.id;
        } catch {
            return null;
        }
    };
    const [isTopBarVisible, setTopBarVisible] = useState(true);
    const fetchFundsData = async () => {
        const userId = getUserId();
        if (!userId) {
            alert('User session has expired. Redirecting to login.');
            window.location.href = '/';
            return;
        }
        try {
            const response = await axios.get(`http://82.115.20.86:5000/api/funds`, {
                params: { userId },
            });
            console.log('Funds Data:', response.data);
            setFundsData(response.data);
        } catch (error) {
            console.error('Error fetching funds data:', error);
            alert('Failed to fetch funds data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFundsData();
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!fundsData) {
        return null; // Prevent rendering if data is not available
    }

    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden', backgroundColor: '#002B5B' }}>
            <Sidebar open={sidebarOpen} onClose={toggleSidebar} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar onMenuClick={toggleSidebar} onSearch={() => { }} isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible} />
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: { xs: '200px', sm: '80px' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: { xs: '16px', sm: '24px' },
                        gap: 3,
                    }}
                >
                    {['Balance', 'Equity', 'Free Margin', 'Profit/Loss', 'Margin Level'].map((key, index) => {
                        // تعیین نام کلیدهای واقعی در JSON
                        const keyMapping: { [key: string]: string } = {
                            'Balance': 'balance',
                            'Equity': 'equity',
                            'Free Margin': 'freeMargin',
                            'Profit/Loss': 'profitLoss',
                            'Margin Level': 'marginLevel',
                        };

                        const dataKey = keyMapping[key] || '';
                        return (
                            <Box
                                key={index}
                                sx={{
                                    width: isMobile ? '100%' : '80%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '16px',
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>{key}</Typography>
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        color: key === 'Profit/Loss'
                                            ? fundsData[dataKey] >= 0
                                                ? 'green'
                                                : 'red'
                                            : '#002B5B',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {fundsData[dataKey]?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 'N/A'}
                                </Typography>

                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default Funds;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    TextField,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface CrudTableProps {
    searchTerm: string;
}

interface User {
    id: number;
    username: string;
    rate_margin: string;
    callmargin: string;
    balance: string;
    symbols: string;
    active: boolean;
}

const CrudTable: React.FC<CrudTableProps> = ({ searchTerm }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchUsers = async () => {
        try {
            const response = await axios.get('http://82.115.20.86:5000/api/users'); // آدرس API
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleOpenUserModal = (user: User) => {
        setSelectedUser(user);
    };

    const handleCloseUserModal = () => {
        setSelectedUser(null);
    };

    const handleToggleActive = async (id: number, currentActive: boolean) => {
        try {
            await axios.post(`http://82.115.20.86:5000/api/users/${id}/toggle-active`, {
                active: currentActive ? 0 : 1, // تغییر مقدار ستون active
            });
            await fetchUsers(); // رفرش کردن جدول پس از به‌روزرسانی
        } catch (error) {
            console.error('Error toggling active status:', error);
        }
    };

    const filteredUsers = users.filter((user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleDeleteUser = async (id: number) => {
        const userConfirmed = window.confirm("Are you sure you want to delete this user?");
        if (!userConfirmed) {
            return; // اگر کاربر کنسل کرد، تابع متوقف شود.
        }

        try {
            const response = await axios.delete(`http://82.115.20.86:5000/api/users/${id}`);
            if (response.status === 200) {
                alert("User deleted successfully!");
                fetchUsers(); // رفرش کردن جدول برای نمایش داده‌های جدید
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            alert("Failed to delete user. Please try again.");
        }
    };

    const [isEditDialogOpen, setEditDialogOpen] = useState(false);
    const [editableUser, setEditableUser] = useState<User | null>(null);

    // حالت برای هر باکس در پنجره ویرایش
    const [editUsername, setEditUsername] = useState('');
    const [editPassword, setEditPassword] = useState('');
    const [editRateMargin, setEditRateMargin] = useState('');
    const [editCallMargin, setEditCallMargin] = useState('');
    const [editBalance, setEditBalance] = useState('');
    const [editSymbols, setEditSymbols] = useState<string[]>([]);

    const handleOpenEditDialog = (user: User) => {
        setEditableUser(user);
        setEditUsername(user.username);
        setEditPassword(''); // پیش‌فرض خالی
        setEditRateMargin(user.rate_margin);
        setEditCallMargin(user.callmargin);
        setEditBalance(user.balance);

        // تبدیل مقادیر سیمبول‌ها به مقادیر جدید
        const mappedSymbols = user.symbols.split(',').map((symbol) => {
            switch (symbol) {
                case 'EURUSD': return 'EURUSD...';
                case 'GBPUSD': return 'GBPUSD...';
                case 'GOLD': return 'XAUUSD...';
                case 'SILVER': return 'XAGUSD...';
                case 'USDCHF': return 'USDCHF...';
                case 'USDJPY': return 'USDJPY...';
                case 'EURNZD': return 'EURNZD...';
                case 'EURJPY': return 'EURJPY...';
                case 'GBPJPY': return 'GBPJPY...';
                case 'US30Cash': return 'US30';
                case 'US100Cash': return 'UT100';
                case 'US500Cash': return 'US500';
                case 'BTCUSD': return 'BTCUSD';
                case 'ETHUSD': return 'ETHUSD';
                default: return symbol;
            }
        });
        setEditSymbols(mappedSymbols);
        setEditDialogOpen(true);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        setEditableUser(null);
    };
    const handleUpdateUser = async () => {
        if (!editableUser) return;

        const updatedUser = {
            username: editUsername,
            password: editPassword || null, // اگر خالی بود به سرور ارسال نشود
            rateMargin: editRateMargin,
            callMargin: editCallMargin,
            balance: editBalance,
            symbols: editSymbols.join(","), // تبدیل لیست سیمبول‌ها به رشته
        };

        try {
            const response = await axios.put(`http://82.115.20.86:5000/api/users/${editableUser.id}`, updatedUser);
            if (response.status === 200) {
                alert("User updated successfully!");
                fetchUsers(); // به‌روزرسانی داده‌ها
                handleCloseEditDialog(); // بستن پنجره
            }
        } catch (error) {
            console.error("Error updating user:", error);
            alert("Failed to update user. Please try again.");
        }
    };
    const [openSymbolsDialog, setOpenSymbolsDialog] = useState(false);
    const [currentSymbols, setCurrentSymbols] = useState<string>('');

    const handleOpenSymbolsDialog = (symbols: string) => {
        setCurrentSymbols(symbols);
        setOpenSymbolsDialog(true);
    };

    const handleCloseSymbolsDialog = () => {
        setOpenSymbolsDialog(false);
        setCurrentSymbols('');
    };

    return (
        <>
            <TableContainer
                component={Paper}
                sx={{
                    marginTop: { xs: '64px', sm: 0 },
                }}
            >
                <Table aria-label="crud table">
                    <TableHead sx={{ backgroundColor: '#002B5B' }}>
                        <TableRow>
                            {isMobile ? (
                                <>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Username</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Active</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Update</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Delete</TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>ID</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Username</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Margin Rate</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Call Margin</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Balance</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Available Symbol</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Active</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Update</TableCell>
                                    <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Delete</TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {filteredUsers.map((user, index) => (
                            <TableRow
                                key={user.id}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? '#f0f8ff' : 'inherit', // رنگ آبی خیلی روشن برای سطرهای زوج
                                }}
                            >
                                {isMobile ? (
                                    <>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Button
                                                onClick={() => handleOpenUserModal(user)}
                                                sx={{ textTransform: 'none' }}
                                            >
                                                {user.username}
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Button
                                                variant="contained"
                                                color={user.active ? 'error' : 'success'}
                                                onClick={() => handleToggleActive(user.id, user.active)}
                                                sx={{ textTransform: 'none', fontWeight: 'bold' }}
                                            >
                                                {user.active ? 'Active' : 'Deactive'}
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleOpenEditDialog(user)}
                                            >
                                                Update
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDeleteUser(user.id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{user.id}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{user.username}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{user.rate_margin}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{user.callmargin}</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            {parseFloat(user.balance) % 1 === 0
                                                ? `${user.balance}.00`
                                                : user.balance}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleOpenSymbolsDialog(user.symbols)}
                                                sx={{ textTransform: 'none' }}
                                            >
                                                View Symbols
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Button
                                                variant="contained"
                                                color={user.active ? 'error' : 'success'}
                                                onClick={() => handleToggleActive(user.id, user.active)}
                                                sx={{ textTransform: 'none', fontWeight: 'bold' }}
                                            >
                                                {user.active ? 'Active' : 'Deactive'}
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleOpenEditDialog(user)}
                                            >
                                                Update
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDeleteUser(user.id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <Dialog open={isEditDialogOpen} onClose={handleCloseEditDialog}>
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent>
                    {/* Username */}
                    <TextField
                        label="Username"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        value={editUsername}
                        onChange={(e) => setEditUsername(e.target.value)}
                    />
                    {/* Password */}
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        value={editPassword}
                        onChange={(e) => setEditPassword(e.target.value)}
                    />
                    {/* Margin Rate */}
                    <TextField
                        label="Margin Rate"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        value={editRateMargin}
                        onChange={(e) => setEditRateMargin(e.target.value)}
                    />
                    {/* Call Margin */}
                    <TextField
                        label="Call Margin"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        value={editCallMargin}
                        onChange={(e) => setEditCallMargin(e.target.value)}
                    />
                    {/* Balance */}
                    <TextField
                        label="Balance"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        value={editBalance}
                        onChange={(e) => setEditBalance(e.target.value)}
                    />
                    {/* Symbols */}
                    <Typography sx={{ marginBottom: 1, fontWeight: 'bold' }}>Available Symbols</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2, fontWeight: 'bold' }}>
                        {['BTCUSD', 'ETHUSD', 'EURUSD...', 'GBPUSD...', 'XAUUSD...', 'XAGUSD...', 'USDCHF...', 'USDJPY...', 'EURNZD...', 'EURJPY...', 'GBPJPY...', 'US30', 'UT100', 'US500'].map((symbol) => (
                            <Button
                                key={symbol}
                                variant={editSymbols.includes(symbol) ? 'contained' : 'outlined'}
                                onClick={() =>
                                    setEditSymbols((prev) =>
                                        prev.includes(symbol)
                                            ? prev.filter((s) => s !== symbol)
                                            : [...prev, symbol]
                                    )
                                }
                                sx={{ textTransform: 'none' }}
                            >
                                {symbol}
                            </Button>
                        ))}
                    </Box>
                    {/* Buttons */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ textTransform: "none" }}
                            onClick={handleUpdateUser}
                        >
                            Update
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCloseEditDialog}
                            sx={{ textTransform: "none", fontWeight: 'bold' }}
                        >
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={openSymbolsDialog} onClose={handleCloseSymbolsDialog} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Available Symbols</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ fontWeight: 'bold' }}>{currentSymbols}</Typography>
                </DialogContent>
                <Button onClick={handleCloseSymbolsDialog} variant="contained" sx={{ margin: 2 }}>
                    Close
                </Button>
            </Dialog>

            {selectedUser && (
                <Dialog open={!!selectedUser} onClose={handleCloseUserModal}>
                    <DialogTitle>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{selectedUser.username}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography sx={{ fontWeight: 'bold' }}>ID: {selectedUser.id}</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>Margin Rate: {selectedUser.rate_margin}</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>Call Margin: {selectedUser.callmargin}</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>Balance: {selectedUser.balance}</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>Available Symbols: {selectedUser.symbols}</Typography>
                    </DialogContent>
                    <Button onClick={handleCloseUserModal} variant="contained" sx={{ margin: 2 }}>
                        Close
                    </Button>
                </Dialog>
            )}
        </>
    );
};

export default CrudTable;

import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, Paper } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post('http://82.115.20.86:5000/api/users/login', { username, password });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('token_expiry', new Date(Date.now() + 3600000).toString()); // اعتبار 1 ساعت

      localStorage.setItem('username', username); // ذخیره نام کاربری در localStorage
      if (username === 'admin') {
        navigate('/crud'); // هدایت کاربر admin به صفحه CRUD
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 403) {
        alert(error.response.data.message); // پیام "Your account is inactive. Please contact the administrator."
      } else {
        console.error('Login failed:', error);
        alert('Login failed. Please check your credentials.');
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 8 }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          onKeyDown={handleKeyDown} // اضافه کردن رویداد به کل فرم
        >
          <Typography component="h1" variant="h5" sx={{ marginBottom: 2 }}>
            Login
          </Typography>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            fullWidth
            sx={{ marginTop: 2 }}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;

import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, InputBase, Box, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


interface UserInfo {
  id: string;
  username: string;
  balance: number;
  equity: number;
  margin: number;
  profitLoss: number;
}
interface Order {
  symbol: string;
  lot: string;
  type: string;
  sl: string;
  tp: string;
  ordertime: string;
  requiredmargin: number;
  strategy_name: string;
  q_table_id: number;
}
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#f1f1f1',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  maxWidth: 300,
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
}));

const SearchIconWrapper = styled('div')({
  padding: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#000',
  width: '100%',
  fontSize: '1rem',
}));

interface TopBarProps {
  onSearch: (value: string) => void;
  onMenuClick: () => void;
  isTopBarVisible: boolean;
  setTopBarVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const TopBar: React.FC<TopBarProps> = ({
  onSearch,
  onMenuClick,
  isTopBarVisible,
  setTopBarVisible
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [userInfo, setUserInfo] = useState({
    id: '',
    username: '',
    balance: 0,
    equity: 0,
    margin: 0,
    profitLoss: 0,
  });
  const [errorOrders, setErrorOrders] = useState<Order[]>([]);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const circleAnimation = useSpring({
    loop: { reverse: true },
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.2)' },
    config: { duration: 1000 },
  });
  const notificationAnimation = useSpring({
    opacity: isNotificationOpen ? 1 : 0,
    transform: isNotificationOpen ? 'translateY(0)' : 'translateY(-50%)',
    config: { tension: 200, friction: 20 },
  });
  const navigate = useNavigate();
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    onSearch(value);
  };

  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchAnimation = useSpring({
    width: isSearchOpen ? 300 : 50,
    opacity: isSearchOpen ? 1 : 0,
    config: { tension: 300, friction: 20 },
  });
  useEffect(() => {
    const checkTokenValidity = () => {
      const token = localStorage.getItem('token');
      const tokenExpiry = localStorage.getItem('token_expiry');

      if (!token || !tokenExpiry || new Date(tokenExpiry) < new Date()) {
        alert("Your session has expired. Please log in again.");
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiry');
        navigate('/');
        return false; // اعتبار توکن تایید نشد.
      }
      return true; // توکن معتبر است.
    };

    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await axios.get('http://82.115.20.86:5000/api/users/info', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response?.data) {
          const { active, errorOrders } = response.data;

          if (active === 1) {
            alert("banned.");
            localStorage.removeItem('token');
            localStorage.removeItem('token_expiry');
            navigate('/');
            return;
          }
          setUserInfo(response.data);
          setErrorOrders(errorOrders || []);
          localStorage.setItem('topBarMargin', response.data.margin.toString());
        } else {
          console.error('No data found in API response.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    // بررسی اعتبار توکن در شروع
    if (!checkTokenValidity()) return;

    // بارگذاری اولیه اطلاعات
    fetchUserData();

    // تنظیم بازه برای تازه‌سازی اطلاعات
    const interval = setInterval(() => {
      if (checkTokenValidity()) {
        fetchUserData();
      } else {
        clearInterval(interval); // اگر توکن منقضی شد، بازه متوقف شود.
      }
    }, 10000); // هر 10 ثانیه یک بار اطلاعات تازه‌سازی می‌شود.

    return () => clearInterval(interval); // پاک کردن بازه زمانی در هنگام خروج از کامپوننت
  }, [navigate]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: '#002B5B',
          padding: '10px',
          transform: isTopBarVisible ? 'translateY(0)' : 'translateY(-110%)',
          transition: 'transform 0.3s ease-in-out', // اضافه کردن انیمیشن ساده
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: { xs: 'wrap', sm: 'nowrap' },
          }}
        >

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' }, // ستونی در موبایل و خطی در دسکتاپ
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'space-between' }, // تنظیم فاصله در حالت خطی
              gap: 2, // فاصله بین المان‌ها
              width: '100%', // پوشش کامل عرض
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1, // فاصله داخلی کوچک‌تر برای آیکون و جستجو
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setSearchOpen(!isSearchOpen)}
              >
                <img
                  src="/images/search.png"
                  alt="Search Icon"
                  style={{ width: "24px", height: "24px", filter: "invert(1)" }}
                />
              </div>
              {isSearchOpen && (
                <animated.div
                  style={{
                    ...searchAnimation,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '5px',
                    overflow: 'hidden',
                  }}
                >
                  <SearchIcon
                    style={{
                      marginRight: 8,
                      color: '#0077b6',
                      cursor: 'default',
                    }}
                  />
                  <StyledInputBase
                    placeholder="Search…"
                    value={searchValue}
                    onChange={handleSearchChange}
                    sx={{ flexGrow: 1 }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => setSearchOpen(false)}
                    sx={{ color: '#0077b6', padding: '4px' }}
                  >
                    ✕
                  </IconButton>
                </animated.div>
              )}

            </Box>

            <Typography variant="body1" sx={{ color: '#fff' }}>
              Balance: ${userInfo.balance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>

            <Typography variant="body1" sx={{ color: '#fff' }}>
              Equity: ${userInfo.equity.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>

            <Typography variant="body1" sx={{ color: '#fff' }}>
              Margin: ${userInfo.margin.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>

            <Typography
              sx={{
                color: userInfo.profitLoss >= 0 ? '#03fc03' : '#fa0217',
              }}
            >
              Profit/Loss: ${userInfo.profitLoss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Typography>

            <animated.div
              onClick={() => setNotificationOpen(true)}
              style={{
                ...circleAnimation,
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: 'linear-gradient(135deg, #00b4d8, #0077b6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
              }}
            >
              <Typography variant="body1" sx={{ color: '#fff', fontWeight: 'bold' }}>
                {errorOrders.length}
              </Typography>
            </animated.div>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              bottom: '-50px', // قرار گرفتن کمی پایین‌تر از TopBar
              right: '20px', // فاصله از سمت راست
              zIndex: 1000,
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            }}
          >
            <IconButton
              color="primary"
              onClick={() => setTopBarVisible((prev) => !prev)}
              sx={{
                padding: '8px',
                '&:hover': { backgroundColor: '#f0f0f0' },
              }}
            >
              {isTopBarVisible ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
            </IconButton>
          </Box>


        </Toolbar>
        {/* Notification Center */}
        {isNotificationOpen && (
          <animated.div
            style={{
              ...notificationAnimation,
              position: 'fixed',
              top: '20%',
              right: '10%',
              width: '90%',
              maxWidth: '600px',
              background: 'linear-gradient(135deg, #0077b6, #0096c7)',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
              borderRadius: '10px',
              color: '#fff',
              padding: '20px',
              zIndex: 1000,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
              Notification Center
            </Typography>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
              <thead>
                <tr>
                  {['Symbol', 'Lot', 'Type', 'SL', 'TP', 'Order Time', 'Margin', 'Strategy', 'Q Table ID'].map((header) => (
                    <th
                      key={header}
                      style={{
                        borderBottom: '2px solid #fff',
                        padding: '10px',
                        textAlign: 'left',
                        color: '#ffdd57',
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {errorOrders.length > 0 ? (
                  errorOrders.map((order, index) => (
                    <tr key={index}>
                      {Object.values(order).map((value, i) => (
                        <td
                          key={i}
                          style={{
                            borderBottom: '1px solid #fff',
                            padding: '8px',
                            textAlign: 'left',
                          }}
                        >
                          {String(value)}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={9} style={{ padding: '10px', textAlign: 'center' }}>
                      No error orders found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <button
              onClick={() => setNotificationOpen(false)}
              style={{
                background: 'linear-gradient(135deg, #00b4d8, #90e0ef)',
                border: 'none',
                color: '#fff',
                padding: '10px 20px',
                borderRadius: '20px',
                cursor: 'pointer',
                fontWeight: 'bold',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'all 0.3s',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            >
              Close
            </button>
          </animated.div>
        )}
      </AppBar>

    </>
  );
};

export default TopBar;

import React, { useEffect, useState } from 'react';
import SidebarAdmin from '../components/SidebarAdmin';
import TopBar from '../components/TopBar';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Dialog, Button, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface Strategy {
    username: string;
    strategy_id: number;
    strategy_name: string;
    strategy_state: string;
    instrument_symbol: string;
    trade_style: string;
    timeframe_analysis: string;
    market_conditions: string;
    risk_reward_ratio: string;
    capital_allocated: string;
    comments: string;
}

const StrategiesAdmin: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [strategies, setStrategies] = useState<Strategy[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // بررسی سشن ادمین
    useEffect(() => {
        const username = localStorage.getItem('username');
        if (username !== 'admin') {
            navigate('/'); // هدایت کاربران غیرمجاز به صفحه /
        }
    }, [navigate]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // تشخیص نسخه موبایل

    const [selectedStrategy, setSelectedStrategy] = useState<Strategy | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = (strategy: Strategy) => {
        setSelectedStrategy(strategy);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setSelectedStrategy(null);
        setDialogOpen(false);
    };

    // دریافت اطلاعات از API
    useEffect(() => {
        const fetchStrategies = async () => {
            try {
                const response = await axios.get('http://82.115.20.86:5000/api/question-responses');
                setStrategies(response.data);
            } catch (error) {
                console.error('Error fetching strategies:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchStrategies();
    }, []);
    const [isTopBarVisible, setTopBarVisible] = useState(true);

    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            {/* سایدبار */}
            <SidebarAdmin open={sidebarOpen} onClose={toggleSidebar} />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* تاپ بار */}
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar onSearch={() => { }} onMenuClick={toggleSidebar} isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible} />
                </Box>

                {/* جدول */}
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: { xs: '250px', sm: '64px' },
                        padding: 2,
                        overflow: 'auto',
                    }}
                >
                    {loading ? (
                        <Typography align="center">Loading...</Typography>
                    ) : strategies.length === 0 ? (
                        <Typography align="center">No strategies found.</Typography>
                    ) : (
                        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#002B5B' }}>
                                    <TableRow>
                                        {isMobile ? (
                                            <>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Username</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Strategy ID</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Strategy Name</TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Username</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Strategy ID</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Strategy Name</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>State</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Instrument</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Trade Style</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Timeframe</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Market Conditions</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Risk/Reward</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Capital</TableCell>
                                                <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Comments</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {strategies.map((strategy, index) => (
                                        <TableRow key={index}>
                                            {isMobile ? (
                                                <>
                                                    <TableCell>
                                                        <Typography
                                                            onClick={() => handleOpenDialog(strategy)}
                                                            sx={{
                                                                cursor: 'pointer',
                                                                color: '#1976d2',
                                                                textDecoration: 'underline',
                                                            }}
                                                        >
                                                            {strategy.username}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>{strategy.strategy_id}</TableCell>
                                                    <TableCell>{strategy.strategy_name}</TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell>{strategy.username}</TableCell>
                                                    <TableCell>{strategy.strategy_id}</TableCell>
                                                    <TableCell>{strategy.strategy_name}</TableCell>
                                                    <TableCell>{strategy.strategy_state}</TableCell>
                                                    <TableCell>{strategy.instrument_symbol}</TableCell>
                                                    <TableCell>{strategy.trade_style}</TableCell>
                                                    <TableCell>{strategy.timeframe_analysis}</TableCell>
                                                    <TableCell>{strategy.market_conditions}</TableCell>
                                                    <TableCell>{strategy.risk_reward_ratio}</TableCell>
                                                    <TableCell>{strategy.capital_allocated}</TableCell>
                                                    <TableCell>{strategy.comments}</TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Box>
            {selectedStrategy && (
                <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                    <Box sx={{ padding: 3 }}>
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>
                            Strategy Details
                        </Typography>
                        <Typography><strong>Username:</strong> {selectedStrategy.username}</Typography>
                        <Typography><strong>Strategy ID:</strong> {selectedStrategy.strategy_id}</Typography>
                        <Typography><strong>Strategy Name:</strong> {selectedStrategy.strategy_name}</Typography>
                        <Typography><strong>State:</strong> {selectedStrategy.strategy_state}</Typography>
                        <Typography><strong>Instrument Symbol:</strong> {selectedStrategy.instrument_symbol}</Typography>
                        <Typography><strong>Trade Style:</strong> {selectedStrategy.trade_style}</Typography>
                        <Typography><strong>Timeframe Analysis:</strong> {selectedStrategy.timeframe_analysis}</Typography>
                        <Typography><strong>Market Conditions:</strong> {selectedStrategy.market_conditions}</Typography>
                        <Typography><strong>Risk/Reward Ratio:</strong> {selectedStrategy.risk_reward_ratio}</Typography>
                        <Typography><strong>Capital Allocated:</strong> {selectedStrategy.capital_allocated}</Typography>
                        <Typography><strong>Comments:</strong> {selectedStrategy.comments}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCloseDialog}
                                sx={{ textTransform: 'none' }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            )}

        </Box>
    );
};

export default StrategiesAdmin;

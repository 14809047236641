import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,

    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/system';
import axios from 'axios';

const OpenPositionsPage: React.FC = () => {

    const [positions, setPositions] = useState<any[]>([]);
    const [filteredPositions, setFilteredPositions] = useState<any[]>([]);
    const [selectedPosition, setSelectedPosition] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // اضافه کردن state برای جستجو
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getUserId = () => {
        const token = localStorage.getItem("token");
        if (!token) return null;

        try {
            const payload = JSON.parse(atob(token.split(".")[1]));
            return payload.id;
        } catch {
            return null;
        }
    };
    const formatPrice = (symbol: string, price: any) => {
        const numericPrice = parseFloat(price);
        if (isNaN(numericPrice)) return '-'; // اگر عدد نبود

        if (
            ['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(symbol)
        ) {
            return numericPrice.toFixed(2);
        } else if (
            ['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(symbol)
        ) {
            return numericPrice.toFixed(3);
        } else if (
            ['XAGUSD...'].includes(symbol)
        ) {
            return numericPrice.toFixed(4);
        } else if (
            ['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(symbol)
        ) {
            return numericPrice.toFixed(5);
        }
        return numericPrice.toString();
    };
    const updatePositions = (newPositions: any[]) => {
        setPositions(newPositions);
    };
    const [previousPrices, setPreviousPrices] = useState<{ [key: string]: { ask: number; bid: number } }>({});

    const updatePreviousPrices = (symbol: string, newPrices: { ask: number; bid: number }) => {
        setPreviousPrices((prev) => ({
            ...prev,
            [symbol]: newPrices,
        }));
    };
    const fetchPositions = async () => {
        try {
            const userId = getUserId();
            if (!userId) {
                alert('User ID not found. Please login again.');
                setLoading(false);
                return;
            }

            const response = await axios.get('http://82.115.20.86:5000/api/open-positions', {
                params: { userId },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });


            const pricesResponse = await axios.get('http://82.115.20.86:5000/api/prices');
            const prices = pricesResponse.data.reduce((acc: any, price: any) => {
                acc[price.symbol] = { ask: price.ask, bid: price.bid };
                return acc;
            }, {});

            const enrichedPositions = response.data.map((position: any) => {
                const currentPrice = prices[position.symbol] || { ask: 0, bid: 0 };
                const previousPrice = previousPrices[position.symbol] || { ask: 0, bid: 0 };

                updatePreviousPrices(position.symbol, currentPrice);

                return {
                    ...position,
                    currentPrice,
                    priceChange: {
                        ask: currentPrice.ask > previousPrice.ask ? 'up' : currentPrice.ask < previousPrice.ask ? 'down' : 'neutral',
                        bid: currentPrice.bid > previousPrice.bid ? 'up' : currentPrice.bid < previousPrice.bid ? 'down' : 'neutral',
                    },
                };
            });

            updatePositions(enrichedPositions); // به‌روزرسانی بدون رفرش ظاهری
        } catch (error) {
            console.error('Error fetching positions:', error);
        } finally {
            setLoading(false); // اطمینان از غیرفعال شدن حالت بارگذاری
        }
    };


    useEffect(() => {
        const interval = setInterval(fetchPositions, 1000); // هر یک ثانیه داده‌ها به‌روزرسانی می‌شوند
        return () => clearInterval(interval); // پاک کردن تایمر هنگام خروج
    }, []);

    // جستجوی موقعیت‌ها بر اساس عبارت ورودی
    useEffect(() => {
        if (searchTerm.trim() === '') {
            setFilteredPositions(positions);
        } else {
            const filtered = positions.filter((position) =>
                Object.values(position).some((value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredPositions(filtered);
        }
    }, [searchTerm, positions]);

    const handleRowClick = (position: any) => {
        setSelectedPosition(position);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedPosition(null);
    };
    const [modifyDialogOpen, setModifyDialogOpen] = useState(false);
    const [modifyPosition, setModifyPosition] = useState<any>(null);
    const [newSL, setNewSL] = useState<string>('');
    const [newTP, setNewTP] = useState<string>('');
    const [closeDialogOpen, setCloseDialogOpen] = useState(false); // جدید
    const [closeLot, setCloseLot] = useState<string>(''); // مقدار Lot
    const [sliderValue, setSliderValue] = useState<number>(0); // مقدار اسلایدر
    const handleCloseClick = (position: any) => {
        setSelectedPosition(position);
        setSliderValue(parseFloat(position.lot)); // مقدار اولیه اسلایدر
        setCloseLot(position.lot); // مقدار اولیه LOT
        setCloseDialogOpen(true);
    };


    const handleModifyClick = (position: any) => {
        setModifyPosition(position);
        setNewSL(position.sl);
        setNewTP(position.tp);
        setModifyDialogOpen(true);
    };

    const handleModifyClose = () => {
        setModifyDialogOpen(false);
        setModifyPosition(null);
    };
    const handleCloseAll = async () => {
        try {
            const response = await axios.put('http://82.115.20.86:5000/api/orders/close-position', {
                id: selectedPosition.id,
                lotClose: 'all',
            });
            alert(response.data.message);
            fetchPositions(); // Refresh the positions
            setCloseDialogOpen(false);
        } catch (error) {
            console.error('Error closing order completely:', error);
            alert('Failed to close the order completely.');
        }
    };

    const handleClosePartly = async () => {
        const lotCloseValue = parseFloat(closeLot);
        const currentLot = parseFloat(selectedPosition.lot);

        if (lotCloseValue <= 0 || lotCloseValue >= currentLot) {
            alert('Invalid Lot To Close value.');
            return;
        }

        try {
            const response = await axios.put('http://82.115.20.86:5000/api/orders/close-position', {
                id: selectedPosition.id,
                lotClose: lotCloseValue,
            });
            alert(response.data.message);
            fetchPositions(); // Refresh the positions
            setCloseDialogOpen(false);
        } catch (error) {
            console.error('Error closing order partially:', error);
            alert('Failed to close the order partially.');
        }
    };
    const [isTopBarVisible, setTopBarVisible] = useState(true);



    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <Sidebar open={sidebarOpen} onClose={toggleSidebar} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar
                        onMenuClick={toggleSidebar}
                        onSearch={(value) => setSearchTerm(value)} // انتقال جستجو
                        isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible}
                    />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: '64px',
                        padding: { xs: '16px', sm: '24px' },
                        overflow: 'auto',
                    }}
                >
                    {loading ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>Loading...</Typography>
                    ) : filteredPositions.length === 0 ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>No open positions found.</Typography>
                    ) : (
                        <TableContainer
                            component={Paper}
                            sx={{
                                boxShadow: 3,
                                borderRadius: 2,
                                marginTop: isMobile ? '150px' : 0, // فاصله در نسخه موبایل
                            }}
                        >
                            <Table>
                                <TableHead sx={{ backgroundColor: '#002B5B' }}>
                                    <TableRow>
                                        {isMobile || (
                                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>ID</TableCell>
                                        )}
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Symbol</TableCell>
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Lot</TableCell>}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Type</TableCell>}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Open Price</TableCell>}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Current Price</TableCell>} {/* اضافه شد */}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>SL</TableCell>}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>TP</TableCell>}
                                        {isMobile || <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Order Time</TableCell>}
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>PNL</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Modify</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Close</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredPositions.map((position) => (
                                        <TableRow key={position.id} hover>
                                            {isMobile || <TableCell>{position.id}</TableCell>}
                                            <TableCell
                                                onClick={() => handleRowClick(position)} // اضافه شدن کلیک روی سیمبول
                                                sx={{
                                                    cursor: 'pointer',
                                                    color: isMobile ? 'blue' : 'inherit',
                                                    textDecoration: isMobile ? 'underline' : 'none',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 'bold' }}>
                                                    <img
                                                        src={`/images/${position.symbol}.png`} // مسیر تصویر
                                                        alt={position.symbol}
                                                        onError={(e) => (e.currentTarget.style.display = 'none')} // پنهان کردن تصویر در صورت خطا
                                                        style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                                                    />
                                                    {position.symbol}
                                                </Box>
                                            </TableCell>
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{position.lot}</TableCell>}
                                            {isMobile || (
                                                <TableCell
                                                    sx={{
                                                        color: position.type === '0' ? 'green' : 'red',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {position.type === '0' ? 'Buy' : 'Sell'}
                                                </TableCell>
                                            )}


                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{formatPrice(position.symbol, position.openPrice)}</TableCell>}
                                            {isMobile || (
                                                <TableCell sx={{ fontWeight: 'bold' }}>
                                                    <Typography
                                                        sx={{
                                                            color:
                                                                position.priceChange.ask === 'up'
                                                                    ? 'green'
                                                                    : position.priceChange.ask === 'down'
                                                                        ? 'red'
                                                                        : 'inherit',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        <strong>Ask:</strong> {formatPrice(position.symbol, position.currentPrice?.ask) || '-'}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color:
                                                                position.priceChange.bid === 'up'
                                                                    ? 'green'
                                                                    : position.priceChange.bid === 'down'
                                                                        ? 'red'
                                                                        : 'inherit',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        <strong>Bid :</strong> {formatPrice(position.symbol, position.currentPrice?.bid) || '-'}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{position.sl}</TableCell>}
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{position.tp}</TableCell>}
                                            {isMobile || <TableCell sx={{ fontWeight: 'bold' }}>{position.ordertime}</TableCell>}
                                            <TableCell
                                                sx={{
                                                    color: position.profit > 0 ? 'green' : position.profit < 0 ? 'red' : 'black', fontWeight: 'bold',
                                                }}
                                            >
                                                {position.profit !== undefined && position.profit !== null ? Number(position.profit).toFixed(2) : '0.00'}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                <Button variant="outlined" color="primary" size="small" onClick={() => handleModifyClick(position)} >
                                                    Modify
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => handleCloseClick(position)} // باز کردن پنجره Close
                                                >
                                                    Close
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    )}
                </Box>
            </Box>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Position Details</DialogTitle>
                <DialogContent>
                    {selectedPosition && (
                        <Box>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>ID:</strong> {selectedPosition.id}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 2 }}>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Symbol:</strong></Typography>
                                <img
                                    src={`/images/${selectedPosition.symbol}.png`}
                                    alt={selectedPosition.symbol}
                                    onError={(e) => (e.currentTarget.style.display = 'none')}
                                    style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                                />
                                <Typography sx={{ fontWeight: 'bold' }}>{selectedPosition.symbol}</Typography>
                            </Box>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Lot:</strong> {selectedPosition.lot}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Type:</strong> {selectedPosition.type === '0' ? 'Buy' : 'Sell'}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Open Price:</strong> {formatPrice(selectedPosition.symbol, selectedPosition.openPrice)}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                <strong>Current Price:</strong>
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Ask:</strong> {formatPrice(selectedPosition.symbol, selectedPosition.currentPrice?.ask) || '-'}</Typography>
                                <Typography sx={{ fontWeight: 'bold' }}><strong>Bid:</strong> {formatPrice(selectedPosition.symbol, selectedPosition.currentPrice?.bid) || '-'}</Typography>
                            </Box>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>SL:</strong> {selectedPosition.sl}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>TP:</strong> {selectedPosition.tp}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Order Time:</strong> {selectedPosition.ordertime}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>PNL:</strong> {selectedPosition.profit}</Typography>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
            <Dialog
                open={modifyDialogOpen}
                onClose={handleModifyClose}
                fullScreen={isMobile} // پوشش کل صفحه در موبایل
                PaperProps={{
                    sx: {
                        width: { xs: '100%', sm: '50%' }, // اندازه در موبایل و دسکتاپ
                        maxWidth: '600px', // حداکثر عرض در دسکتاپ
                        height: { xs: '100%', sm: 'auto' }, // پر کردن کل صفحه در موبایل
                        margin: 'auto', // مرکزیت در دسکتاپ
                        borderRadius: { xs: 0, sm: '16px' }, // گرد کردن گوشه‌ها
                        boxShadow: 24, // افزودن سایه جذاب
                        padding: 0, // حذف padding پیش‌فرض
                        backgroundColor: '#f7f9fc', // پس‌زمینه نرم و ملایم
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                <Box>
                    <DialogTitle
                        sx={{
                            backgroundColor: '#002B5B',
                            color: '#fff',
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            borderBottom: '2px solid #ccc', // خط برای جدا کردن هدر
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                            Modify Position
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            <img
                                src={`/images/${modifyPosition?.symbol}.png`}
                                alt={modifyPosition?.symbol}
                                onError={(e) => (e.currentTarget.style.display = 'none')}
                                style={{ width: '32px', height: '32px', borderRadius: '50%' }}
                            />
                            <Typography sx={{ fontSize: '16px', color: '#FFD700', fontWeight: 'bold' }}>
                                {modifyPosition?.symbol}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    color: modifyPosition?.type === '0' ? '#4CAF50' : '#F44336',
                                    fontWeight: 'bold',
                                }}
                            >
                                {modifyPosition?.type === '0' ? 'Buy' : 'Sell'}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#008080', fontWeight: 'bold' }}>
                                Lot: {modifyPosition?.lot || '-'}
                            </Typography>
                        </Box>
                    </DialogTitle>

                    <DialogContent
                        sx={{
                            padding: '24px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4, // افزایش فاصله بین خطوط
                            justifyContent: 'center',
                            flexGrow: 1, // برای پر کردن فضای باقی‌مانده
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>Ask:</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#008080', fontWeight: 'bold' }}>
                                {formatPrice(modifyPosition?.symbol, modifyPosition?.currentPrice?.ask) || '-'}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                marginX: '16px', // فاصله از چپ و راست
                            }}
                        >
                            <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>SL:</Typography>
                            <input
                                type="text"
                                value={newSL}
                                onChange={(e) => setNewSL(e.target.value)}
                                style={{
                                    width: '100%',
                                    padding: '12px',
                                    borderRadius: '8px',
                                    border: '1px solid #ccc',
                                    fontSize: '16px',
                                }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>Bid:</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#DC143C', fontWeight: 'bold' }}>
                                {formatPrice(modifyPosition?.symbol, modifyPosition?.currentPrice?.bid) || '-'}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                marginX: '16px', // فاصله از چپ و راست
                            }}
                        >
                            <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>TP:</Typography>
                            <input
                                type="text"
                                value={newTP}
                                onChange={(e) => setNewTP(e.target.value)}
                                style={{
                                    width: '100%',
                                    padding: '12px',
                                    borderRadius: '8px',
                                    border: '1px solid #ccc',
                                    fontSize: '16px',
                                }}
                            />
                        </Box>
                    </DialogContent>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around', // تنظیم فاصله دکمه‌ها
                            padding: '16px',
                            borderTop: '1px solid #ccc',
                            position: { xs: 'absolute', sm: 'relative' },
                            bottom: { xs: '0', sm: 'auto' },
                            width: '100%',
                        }}
                    >
                        <Button
                            onClick={handleModifyClose}
                            variant="outlined"
                            color="secondary"
                            sx={{ padding: '12px 24px', fontWeight: 'bold' }}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={async () => {
                                const { ask, bid } = modifyPosition?.currentPrice || {};
                                const isValidNumber = (value: string) =>
                                    value === "0" || /^\d*\.?\d+$/.test(value); // بررسی عدد صحیح یا اعشاری

                                if (!isValidNumber(newSL) || !isValidNumber(newTP)) {
                                    alert("Please enter valid numeric values for SL and TP.");
                                    return;
                                }

                                if (modifyPosition.type === "0") {
                                    // نوع Buy
                                    const maxAsk = parseFloat(ask) * 3;
                                    const minAsk = parseFloat(ask) / 3;

                                    if ((newSL !== "0" && parseFloat(newSL) >= parseFloat(ask)) ||
                                        (newTP !== "0" && parseFloat(newTP) <= parseFloat(ask))) {
                                        alert("For Buy: SL must be less than Ask, and TP must be greater than Ask.");
                                        return;
                                    }

                                    if ((newSL !== "0" && (parseFloat(newSL) > maxAsk || parseFloat(newSL) < minAsk)) ||
                                        (newTP !== "0" && (parseFloat(newTP) > maxAsk || parseFloat(newTP) < minAsk))) {
                                        alert(`For Buy: SL and TP must be between ${minAsk.toFixed(5)} and ${maxAsk.toFixed(5)}.`);
                                        return;
                                    }
                                } else {
                                    // نوع Sell
                                    const maxBid = parseFloat(bid) * 3;
                                    const minBid = parseFloat(bid) / 3;

                                    if ((newSL !== "0" && parseFloat(newSL) <= parseFloat(bid)) ||
                                        (newTP !== "0" && parseFloat(newTP) >= parseFloat(bid))) {
                                        alert("For Sell: SL must be greater than Bid, and TP must be less than Bid.");
                                        return;
                                    }

                                    if ((newSL !== "0" && (parseFloat(newSL) > maxBid || parseFloat(newSL) < minBid)) ||
                                        (newTP !== "0" && (parseFloat(newTP) > maxBid || parseFloat(newTP) < minBid))) {
                                        alert(`For Sell: SL and TP must be between ${minBid.toFixed(5)} and ${maxBid.toFixed(5)}.`);
                                        return;
                                    }
                                }


                                try {
                                    await axios.put('http://82.115.20.86:5000/api/positions/update', {
                                        id: modifyPosition.id,
                                        sl: newSL,
                                        tp: newTP,
                                    });
                                    alert('Position updated successfully!');
                                    fetchPositions(); // بازخوانی داده‌ها
                                    handleModifyClose();
                                } catch (error) {
                                    console.error('Error updating position:', error);
                                    alert('Failed to update position.');
                                }
                            }}
                            variant="contained"
                            color="primary"
                            sx={{ padding: '12px 24px', fontWeight: 'bold' }}
                        >
                            Submit
                        </Button>

                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={closeDialogOpen}
                onClose={() => setCloseDialogOpen(false)}
                fullScreen={isMobile}
                PaperProps={{
                    sx: {
                        width: { xs: '100%', sm: '50%' },
                        maxWidth: '600px',
                        margin: 'auto',
                        borderRadius: { xs: 0, sm: '16px' },
                        boxShadow: 24,
                        backgroundColor: '#f7f9fc',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: '#002B5B',
                        color: '#fff',
                        padding: '16px',
                        borderBottom: '2px solid #ccc',
                    }}
                >
                    {selectedPosition && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                                Close Position
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography sx={{ color: '#FFD700', fontSize: '16px', fontWeight: 'bold' }}>
                                    Symbol: {selectedPosition.symbol}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: selectedPosition.type === 0 ? '#4CAF50' : '#F44336',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Type: {selectedPosition.type === '0' ? 'Buy' : 'Sell'}
                                </Typography>
                                <Typography sx={{ fontSize: '16px', color: '#008080', fontWeight: 'bold' }}>
                                    Lot: {selectedPosition.lot}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </DialogTitle>

                <DialogContent
                    sx={{
                        padding: '24px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <Typography sx={{ fontWeight: 'bold' }}>Lot To Close:</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <input
                            type="text"
                            value={closeLot}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d{0,2}$/.test(value)) {
                                    setCloseLot(value);
                                }
                            }}
                            style={{
                                width: '100%',
                                padding: '12px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                fontSize: '16px',
                            }}
                        />
                        <input
                            type="range"
                            min={0.01}
                            max={parseFloat(selectedPosition?.lot || '0')}
                            step={0.01}
                            value={sliderValue}
                            onChange={(e) => {
                                setSliderValue(parseFloat(e.target.value));
                                setCloseLot(e.target.value);
                            }}
                            style={{ width: '100%' }}
                        />

                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ width: '30%' }}
                            onClick={handleCloseAll} // اضافه شد
                        >
                            Close All
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{ width: '30%' }}
                            onClick={handleClosePartly} // اضافه شد
                        >
                            Close Partly
                        </Button>

                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setCloseDialogOpen(false)}
                            sx={{ width: '30%' }}
                        >
                            Exit
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>







        </Box>
    );
};

export default OpenPositionsPage;

import React, { useEffect, useState } from 'react';
import SidebarAdmin from '../components/SidebarAdmin';
import TopBar from '../components/TopBar';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import axios from 'axios';
interface Order {
    symbol: string;
    type: string;
    [key: string]: any; // برای باقی فیلدها
}

interface Price {
    symbol: string;
    ask: string;
    bid: string;
}


const OrdersAdmin: React.FC = () => {
    const [orders, setOrders] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState<any>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    useEffect(() => {
        fetchOrders();
        const interval = setInterval(() => {
            fetchOrders();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await axios.get('http://82.115.20.86:5000/api/orders-admin');
            const { orders, prices } = response.data;
            const updatedOrders = orders.map((order: Order) => {
                const priceData = prices.find((p: Price) => p.symbol.trim().toUpperCase() === order.symbol.trim().toUpperCase());
                if (!priceData) {
                    console.warn(`Price data not found for symbol: ${order.symbol}`);
                    return { ...order, currentPrice: 'N/A' };
                }

                const { ask, bid } = priceData;
                const currentPrice =
                    order.type === 'Buy Limit' || order.type === 'Buy Stop'
                        ? parseFloat(ask)
                        : parseFloat(bid);

                return {
                    ...order,
                    currentPrice: currentPrice.toFixed(
                        order.symbol === 'XAGUSD...'
                            ? 4
                            : ['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(order.symbol)
                                ? 2
                                : ['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(order.symbol)
                                    ? 3
                                    : 5
                    ),
                };
            });


            setOrders(() => {
                const uniqueOrders = updatedOrders.filter(
                    (order: Order, index: number, self: Order[]) =>
                        index === self.findIndex((o: Order) => o.id === order.id) // جلوگیری از تکرار بر اساس `id`
                );
                return uniqueOrders;
            });
            setLoading(false); // اضافه کردن این خط
        } catch (error) {
            console.error('Error fetching orders:', error);
            setLoading(false); // در صورت خطا هم بارگذاری تمام شود
        }
    };


    const handleOpenDialog = (details: any) => {
        setSelectedDetails(details);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedDetails(null);
    };
    const [isTopBarVisible, setTopBarVisible] = useState(true);
    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <SidebarAdmin open={sidebarOpen} onClose={toggleSidebar} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar
                        onMenuClick={toggleSidebar}
                        onSearch={() => { }}
                        isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible}
                    />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: { xs: '230px', sm: '64px' },
                        padding: { xs: 2, sm: 3 },
                        overflow: 'auto',
                    }}
                >
                    {loading ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>Loading...</Typography>
                    ) : orders.length === 0 ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>No orders found.</Typography>
                    ) : (
                        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#002B5B' }}>
                                    <TableRow>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Username</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Strategy Id</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Symbol</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Lot</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Type</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Price</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Current Price</TableCell>

                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>SL</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>TP</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Order Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders.map((order, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{order.username}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                <Button
                                                    onClick={() => handleOpenDialog(order)}
                                                    variant="outlined"
                                                    sx={{
                                                        color: '#fff',
                                                        borderColor: '#1e88e5',
                                                        backgroundColor: '#1e88e5',
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            backgroundColor: '#1565c0',
                                                            borderColor: '#1565c0',
                                                        },
                                                        borderRadius: '8px',
                                                        fontWeight: 'bold',
                                                        padding: '4px 8px',
                                                    }}
                                                >
                                                    {order.strategy_id}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <img
                                                        src={`/images/${order.symbol}.png`}
                                                        alt={order.symbol}
                                                        onError={(e) => (e.currentTarget.style.display = 'none')}
                                                        style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                                                    />
                                                    {order.symbol}
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(order.lot).toFixed(2)}</TableCell>
                                            <TableCell
                                                sx={{
                                                    color: ['Buy Limit', 'Buy Stop'].includes(
                                                        (() => {
                                                            switch (order.type) {
                                                                case '2': return 'Buy Limit';
                                                                case '3': return 'Sell Limit';
                                                                case '4': return 'Buy Stop';
                                                                case '5': return 'Sell Stop';
                                                                default: return 'Unknown';
                                                            }
                                                        })()
                                                    )
                                                        ? 'green'
                                                        : ['Sell Limit', 'Sell Stop'].includes(
                                                            (() => {
                                                                switch (order.type) {
                                                                    case '2': return 'Buy Limit';
                                                                    case '3': return 'Sell Limit';
                                                                    case '4': return 'Buy Stop';
                                                                    case '5': return 'Sell Stop';
                                                                    default: return 'Unknown';
                                                                }
                                                            })()
                                                        )
                                                            ? 'red'
                                                            : 'inherit',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {(() => {
                                                    switch (order.type) {
                                                        case '2': return 'Buy Limit';
                                                        case '3': return 'Sell Limit';
                                                        case '4': return 'Buy Stop';
                                                        case '5': return 'Sell Stop';
                                                        default: return 'Unknown';
                                                    }
                                                })()}
                                            </TableCell>

                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(order.price).toFixed(2)}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{order.currentPrice || '-'}</TableCell>

                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(order.sl).toFixed(2)}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(order.tp).toFixed(2)}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{order.ordertime}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Box>

            {/* Dialog for Details */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>Strategy Details</DialogTitle>
                <DialogContent>
                    {selectedDetails && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Username:</strong> {selectedDetails.username}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Strategy Name:</strong> {selectedDetails.strategy_name}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Instrument Symbol:</strong> {selectedDetails.instrument_symbol}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Trade Style:</strong> {selectedDetails.trade_style}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Timeframe Analysis:</strong> {selectedDetails.timeframe_analysis}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Market Conditions:</strong> {selectedDetails.market_conditions}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Risk Reward Ratio:</strong> {selectedDetails.risk_reward_ratio}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Capital Allocated:</strong> {selectedDetails.capital_allocated}</Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default OrdersAdmin;

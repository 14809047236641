import React, { useEffect, useState } from 'react';
import SidebarAdmin from '../components/SidebarAdmin';
import TopBar from '../components/TopBar';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface UserFunds {
    userId: number;
    username: string;
    balance: string;
    equity: string;
    profitLoss: string;
    freeMargin: string;
    marginUsed: string;
}

const FundsAdmin: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [userFunds, setUserFunds] = useState<UserFunds[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // بررسی سشن ادمین
    useEffect(() => {
        const username = localStorage.getItem('username');
        if (username !== 'admin') {
            navigate('/'); // هدایت کاربران غیرمجاز به صفحه /
        }
    }, [navigate]);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    // دریافت اطلاعات کاربران
    useEffect(() => {
        const fetchUserFunds = async () => {
            try {
                const response = await axios.get('http://82.115.20.86:5000/api/users-funds');
                setUserFunds(response.data);
            } catch (error) {
                console.error('Error fetching user funds:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserFunds();
    }, []);
    const [isTopBarVisible, setTopBarVisible] = useState(true);
    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            {/* سایدبار */}
            <SidebarAdmin open={sidebarOpen} onClose={toggleSidebar} />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* تاپ بار */}
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar onSearch={() => { }} onMenuClick={toggleSidebar} isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible} />
                </Box>

                {/* جدول */}
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: { xs: '250px', sm: '80px' },
                        padding: 2,
                        height: 'calc(100vh - 64px)', // تنظیم ارتفاع برای پوشش کل صفحه بجز تاپ‌بار
                        overflow: 'hidden', // حذف اسکرول اضافی
                    }}
                >
                    {loading ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>Loading...</Typography>
                    ) : userFunds.length === 0 ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>No users found.</Typography>
                    ) : (
                        <TableContainer
                            component={Paper}
                            sx={{
                                boxShadow: 3,
                                borderRadius: 2,
                                height: '100%',
                                overflow: 'auto', // فعال کردن اسکرول
                            }}
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                backgroundColor: '#002B5B', // پس‌زمینه هدر
                                                color: '#FFFFFF', // رنگ متن
                                                fontWeight: 'bold',
                                                position: 'sticky', // تنظیم حالت چسبان
                                                top: 0, // بالا چسبیده شود
                                                zIndex: 1, // اولویت نمایش
                                            }}
                                        >
                                            User ID
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: '#002B5B',
                                                color: '#FFFFFF',
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                            }}
                                        >
                                            Username
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: '#002B5B',
                                                color: '#FFFFFF',
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                            }}
                                        >
                                            Balance
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: '#002B5B',
                                                color: '#FFFFFF',
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                            }}
                                        >
                                            Equity
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: '#002B5B',
                                                color: '#FFFFFF',
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                            }}
                                        >
                                            Profit/Loss
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: '#002B5B',
                                                color: '#FFFFFF',
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                            }}
                                        >
                                            Free Margin
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: '#002B5B',
                                                color: '#FFFFFF',
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                            }}
                                        >
                                            Margin Used
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {userFunds.map((user) => (
                                        <TableRow key={user.userId}>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{user.userId}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{user.username}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{user.balance}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{user.equity}</TableCell>
                                            <TableCell
                                                sx={{
                                                    color: parseFloat(user.profitLoss) >= 0 ? 'green' : 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {user.profitLoss}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{user.freeMargin}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{user.marginUsed}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default FundsAdmin;

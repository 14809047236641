import React, { useEffect, useState } from 'react';
import SidebarAdmin from '../components/SidebarAdmin';
import TopBar from '../components/TopBar';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
interface Position {
    id: number;
    username: string;
    strategy_id: string;
    symbol: string;
    lot: number;
    type: string;
    open_price: number;
    close_price: number;
    sl: number;
    tp: number;
    open_time: string;
    close_time: string;
    profit: number;
}


const ClosePositionsAdmin: React.FC = () => {
    const [positions, setPositions] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState<any>(null); // اطلاعات انتخاب‌شده برای نمایش
    const [dialogOpen, setDialogOpen] = useState(false); // وضعیت باز بودن پنجره

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const username = localStorage.getItem('username');
        if (username !== 'admin') {
            navigate('/');
        } else {
            setLoading(true); // فقط بار اول loading را true کنید
            fetchPositions();
            setLoading(false); // بعد از بارگذاری اولیه آن را false کنید
            const interval = setInterval(fetchPositions, 900000);
            return () => clearInterval(interval);
        }
    }, [navigate]);

    const fetchPositions = async () => {
        try {
            const response = await axios.get('http://82.115.20.86:5000/api/close-positions-admin');
            setPositions(response.data); // داده‌های جدید جایگزین داده‌های قبلی می‌شوند
        } catch (error) {
            console.error('Error fetching positions:', error);
        }
    };

    // تابع برای باز کردن پنجره با جزئیات
    const handleOpenDialog = (details: any) => {
        setSelectedDetails(details);
        setDialogOpen(true);
    };

    // تابع برای بستن پنجره
    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedDetails(null);
    };
    const [searchTerm, setSearchTerm] = useState('');

    const filteredPositions = positions.filter(position =>
        position.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        position.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
        position.open_time.toLowerCase().includes(searchTerm.toLowerCase()) ||
        position.close_time.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const [isTopBarVisible, setTopBarVisible] = useState(true);

    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);

    const handleSort = (key: string) => {
        let direction: 'asc' | 'desc' = 'asc';

        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedData = [...filteredPositions].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setSortConfig({ key, direction });
        setPositions(sortedData);
    };


    return (
        <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            <SidebarAdmin open={sidebarOpen} onClose={toggleSidebar} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: { sm: '80px', xs: 0 },
                        right: 0,
                        zIndex: 2,
                    }}
                >
                    <TopBar
                        onMenuClick={toggleSidebar}
                        onSearch={(value) => setSearchTerm(value)}
                        isTopBarVisible={isTopBarVisible}
                        setTopBarVisible={setTopBarVisible}
                    />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        marginTop: { xs: '230px', sm: '64px' },
                        padding: { xs: 2, sm: 3 },
                        overflow: 'auto',
                    }}
                >
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '16px',
                            marginBottom: '16px',
                            backgroundColor: '#002B5B',
                            color: '#fff',
                            borderRadius: 2,
                            boxShadow: 3,
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Total PNL:
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                color: positions.reduce((total, position) => total + parseFloat(position.profit || 0), 0) >= 0 ? 'green' : 'red',
                            }}
                        >
                            {positions.reduce((total, position) => total + parseFloat(position.profit || 0), 0).toFixed(2)}
                        </Typography>
                    </Paper>
                    {loading ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>Loading...</Typography>
                    ) : positions.length === 0 ? (
                        <Typography align="center" sx={{ fontWeight: 'bold' }}>No positions found.</Typography>
                    ) : (
                        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#002B5B' }}>
                                    <TableRow>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('username')}>
                                            Username ▲▼
                                        </TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Strategy Id</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('symbol')}>
                                            Symbol ▲▼
                                        </TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Lot</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Type</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Open Price</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Close Price</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>SL</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>TP</TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('open_time')}>
                                            Open Time ▲▼
                                        </TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSort('close_time')}>
                                            Close Time ▲▼
                                        </TableCell>
                                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Profit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredPositions.map((position, index) => (
                                        <TableRow key={position.idx}>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{position.username}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                <Button
                                                    onClick={() => handleOpenDialog(position)}
                                                    variant="outlined"
                                                    sx={{
                                                        color: '#fff',
                                                        borderColor: '#1e88e5',
                                                        backgroundColor: '#1e88e5',
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            backgroundColor: '#1565c0',
                                                            borderColor: '#1565c0',
                                                        },
                                                        borderRadius: '8px',
                                                        fontWeight: 'bold',
                                                        padding: '4px 8px',
                                                    }}
                                                >
                                                    {position.strategy_id}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <img
                                                        src={`/images/${position.symbol}.png`}
                                                        alt={position.symbol}
                                                        onError={(e) => (e.currentTarget.style.display = 'none')}
                                                        style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                                                    />
                                                    {position.symbol}
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{parseFloat(position.lot).toFixed(2)}</TableCell>
                                            <TableCell
                                                sx={{
                                                    color: position.type === 'SELL' ? 'red' : 'green',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {position.type === 'SELL' ? 'Sell' : 'Buy'}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    if (['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(position.symbol)) {
                                                        return parseFloat(position.open_price).toFixed(2);
                                                    } else if (['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(position.symbol)) {
                                                        return parseFloat(position.open_price).toFixed(3);
                                                    } else if (['XAGUSD...'].includes(position.symbol)) {
                                                        return parseFloat(position.open_price).toFixed(4);
                                                    } else if (['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(position.symbol)) {
                                                        return parseFloat(position.open_price).toFixed(5);
                                                    } else {
                                                        return parseFloat(position.open_price).toFixed(2); // پیش‌فرض
                                                    }
                                                })()}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    if (['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(position.symbol)) {
                                                        return parseFloat(position.close_price).toFixed(2);
                                                    } else if (['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(position.symbol)) {
                                                        return parseFloat(position.close_price).toFixed(3);
                                                    } else if (['XAGUSD...'].includes(position.symbol)) {
                                                        return parseFloat(position.close_price).toFixed(4);
                                                    } else if (['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(position.symbol)) {
                                                        return parseFloat(position.close_price).toFixed(5);
                                                    } else {
                                                        return parseFloat(position.close_price).toFixed(2);
                                                    }
                                                })()}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    if (['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(position.symbol)) {
                                                        return parseFloat(position.sl).toFixed(2);
                                                    } else if (['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(position.symbol)) {
                                                        return parseFloat(position.sl).toFixed(3);
                                                    } else if (['XAGUSD...'].includes(position.symbol)) {
                                                        return parseFloat(position.sl).toFixed(4);
                                                    } else if (['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(position.symbol)) {
                                                        return parseFloat(position.sl).toFixed(5);
                                                    } else {
                                                        return parseFloat(position.sl).toFixed(2);
                                                    }
                                                })()}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                {(() => {
                                                    if (['BTCUSD', 'ETHUSD', 'XAUUSD...', 'US30', 'UT100', 'US500'].includes(position.symbol)) {
                                                        return parseFloat(position.tp).toFixed(2);
                                                    } else if (['USDJPY...', 'EURJPY...', 'GBPJPY...'].includes(position.symbol)) {
                                                        return parseFloat(position.tp).toFixed(3);
                                                    } else if (['XAGUSD...'].includes(position.symbol)) {
                                                        return parseFloat(position.tp).toFixed(4);
                                                    } else if (['EURUSD...', 'GBPUSD...', 'USDCHF...', 'EURNZD...'].includes(position.symbol)) {
                                                        return parseFloat(position.tp).toFixed(5);
                                                    } else {
                                                        return parseFloat(position.tp).toFixed(2);
                                                    }
                                                })()}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{position.open_time}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{position.close_time}</TableCell>
                                            <TableCell
                                                sx={{
                                                    color: parseFloat(position.profit) < 0 ? 'red' : 'green',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {parseFloat(position.profit).toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Box>

            {/* Dialog for Details */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>Strategy Details</DialogTitle>
                <DialogContent>
                    {selectedDetails && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Username:</strong> {selectedDetails.username}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Strategy Name:</strong> {selectedDetails.strategy_name}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Instrument Symbol:</strong> {selectedDetails.instrument_symbol}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Trade Style:</strong> {selectedDetails.trade_style}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Timeframe Analysis:</strong> {selectedDetails.timeframe_analysis}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Market Conditions:</strong> {selectedDetails.market_conditions}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Risk Reward Ratio:</strong> {selectedDetails.risk_reward_ratio}</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}><strong>Capital Allocated:</strong> {selectedDetails.capital_allocated}</Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" sx={{ fontWeight: 'bold' }}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ClosePositionsAdmin;

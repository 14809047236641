import React, { useEffect, useState } from 'react';
import SidebarAdmin from '../components/SidebarAdmin';
import CrudTable from '../components/CrudTable'; // تغییر به CrudTable
import TopBar from '../components/TopBar';
import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const Crud: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const username = localStorage.getItem('username');
    if (username !== 'admin') {
      navigate('/'); // هدایت کاربران غیرمجاز به صفحه لاگین
    }
  }, [navigate]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const [isPasswordDialogOpen, setPasswordDialogOpen] = useState(false);

  const togglePasswordDialog = () => {
    setPasswordDialogOpen(!isPasswordDialogOpen);
  };
  const handleSubmit = async () => {
    if (newPassword !== repeatPassword) {
      alert('Passwords do not match!');
      return;
    }
    try {
      await axios.post('http://82.115.20.86:5000/api/users/change-password', {
        newPassword, // فقط ارسال پسورد جدید
      });
      alert('Password updated successfully!');
      togglePasswordDialog();
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Failed to update password.');
    }
  };
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rateMargin, setRateMargin] = useState('');
  const [callMargin, setCallMargin] = useState('');
  const [symbols, setSymbols] = useState<string[]>([]);
  const [balance, setBalance] = useState('');
  const toggleCreateDialog = () => {
    setCreateDialogOpen(!isCreateDialogOpen);
  };
  const handleCreateUser = async () => {
    if (!username || !password) {
      alert("Username and Password are required!");
      return;
    }

    try {
      const response = await axios.post("http://82.115.20.86:5000/api/users/create", {
        username,
        password,
        rateMargin: rateMargin || 0,
        callMargin: callMargin || 0,
        balance: balance || 0,
        symbols: symbols.join(","),
      });

      if (response.status === 201) {
        alert("User created successfully!");
        toggleCreateDialog();
        setUsername("");
        setPassword("");
        setRateMargin("");
        setCallMargin("");
        setSymbols([]);
        setBalance("");
        window.location.reload();
      }
    } catch (error: any) {
      if (error.response?.status === 409) {
        alert("Username already exists! Please choose another one.");
      } else {
        alert("Failed to create user. Please try again.");
      }
    }
  };
  const [isTopBarVisible, setTopBarVisible] = useState(true)


  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      <SidebarAdmin open={sidebarOpen} onClose={toggleSidebar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: { sm: '80px', xs: 0 },
            right: 0,
            zIndex: 2,
          }}
        >
          <TopBar
            onSearch={handleSearch}
            onMenuClick={toggleSidebar}
            isTopBarVisible={isTopBarVisible}
            setTopBarVisible={setTopBarVisible}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            marginTop: { xs: '96px', sm: '64px' }, // تنظیم فاصله برای موبایل و دسکتاپ
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            padding: 2,
          }}
        >
          {/* دکمه‌ها */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={toggleCreateDialog} // اضافه کردن این خط
              sx={{ textTransform: 'none', fontWeight: 'bold', marginTop: { xs: 8, sm: 2 } }}
            >
              Create
            </Button>
            <Dialog open={isCreateDialogOpen} onClose={toggleCreateDialog}>
              <Box sx={{ padding: 2, width: 400 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Create New User
                </Typography>
                <TextField
                  label="Username"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  label="Rate Margin(%)"
                  type="number"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  value={rateMargin}
                  onChange={(e) => setRateMargin(e.target.value)}
                />
                <TextField
                  label="Call Margin"
                  type="number"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  value={callMargin}
                  onChange={(e) => setCallMargin(e.target.value)}
                />
                <Typography sx={{ marginBottom: 1 }}>Symbols</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                  {['BTCUSD', 'ETHUSD', 'EURUSD...', 'GBPUSD...', 'XAUUSD...', 'XAGUSD...', 'USDCHF...', 'USDJPY...', 'EURNZD...', 'EURJPY...', 'GBPJPY...', 'US30', 'UT100', 'US500'].map((symbol) => (
                    <Button
                      key={symbol}
                      variant={symbols.includes(symbol) ? 'contained' : 'outlined'}
                      onClick={() =>
                        setSymbols((prev) =>
                          prev.includes(symbol) ? prev.filter((s) => s !== symbol) : [...prev, symbol]
                        )
                      }
                      sx={{ textTransform: 'none' }}
                    >
                      {symbol}
                    </Button>
                  ))}
                </Box>
                <TextField
                  label="Balance"
                  type="number"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  value={balance}
                  onChange={(e) => setBalance(e.target.value)}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateUser} // اتصال تابع
                    sx={{ textTransform: "none" }}
                  >
                    Create
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={toggleCreateDialog}
                    sx={{ textTransform: 'none' }}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Dialog>

            <Button
              variant="outlined"
              color="warning"
              onClick={togglePasswordDialog}
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                borderWidth: 2,
                marginTop: { xs: 8, sm: 2 },
              }}
            >
              Change Admin Password
            </Button>
            <Dialog open={isPasswordDialogOpen} onClose={togglePasswordDialog}>
              <Box sx={{ padding: 2 }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Change Admin Password
                </Typography>
                <TextField
                  label="Enter a new password"
                  type="password"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                  label="Repeat new password"
                  type="password"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ textTransform: 'none' }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={togglePasswordDialog}
                    sx={{ textTransform: 'none' }}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Dialog>

          </Box>
          {/* جدول */}
          <CrudTable searchTerm={searchTerm} />
        </Box>
      </Box>
    </Box>
  );
};

export default Crud;
